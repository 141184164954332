@media all and (min-width: 480px) {
  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
  .profile {
    margin: 0 auto;
    max-width: 50%;
  }
  .profile > .row {
    margin-bottom: 1%;
  }
}

@media all and (max-width: 480px) {
  .Signup,.profile{
    margin: 0 5%;
  }
}