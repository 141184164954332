.fl-nav-icon-toggled {
  position: relative;
}

.fl-nav-icon-toggled.fl-page-nav-right .fl-page-nav-collapse ul.navbar-nav {
  float: none !important;
}

.fl-nav-icon-toggled.fl-page-nav-right .fl-page-header-row .fl-page-header-logo {
  padding-bottom: 5px;
}

.fl-nav-icon-toggled.fl-page-nav-left .fl-page-header-row .fl-page-header-logo {
  padding-bottom: 5px;
}

.fl-nav-icon-toggled.fl-page-nav-left .fl-page-nav ul.navbar-nav > li:first-child a {
  padding-left: 17px;
}

.fl-nav-icon-toggled.fl-page-nav-centered-inline-logo {
  text-align: left;
}

.fl-nav-icon-toggled.fl-page-nav-centered-inline-logo .fl-page-header-logo {
  display: block;
}

.fl-nav-icon-toggled.fl-page-header-vertical {
  top: 0;
}

.fl-nav-icon-toggled .fl-page-nav-collapse {
  border-top: 1px solid #e6e6e6;
  padding-bottom: 10px;
}

.fl-nav-icon-toggled .fl-page-nav-collapse.collapse {
  display: none !important;
  visibility: hidden !important;
}

.fl-nav-icon-toggled .fl-page-nav-collapse.collapse.in {
  display: block !important;
  visibility: visible !important;
}

.fl-nav-icon-toggled .fl-page-nav-collapse ul.navbar-nav {
  display: block;
  float: none;
}

.fl-nav-icon-toggled .fl-page-nav-collapse ul.navbar-nav > li {
  border-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: block;
  float: none;
  position: relative;
}

.fl-nav-icon-toggled .fl-page-nav-collapse ul.navbar-nav > li > a {
  color: #424244;
  text-align: left;
}

.fl-nav-icon-toggled .fl-page-nav-collapse ul.navbar-nav > li > a:hover,
.fl-nav-icon-toggled .fl-page-nav-collapse ul.navbar-nav > li > a:focus {
  color: #1d75bd;
}

.fl-nav-icon-toggled .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item > a {
  color: #1d75bd;
}

.fl-nav-icon-toggled .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item ~ li.current-menu-item > a {
  color: #424244;
}

.fl-nav-icon-toggled .fl-page-nav-collapse ul.sub-menu li {
  border-color: #e6e6e6;
}

.fl-nav-icon-toggled .fl-page-nav-collapse ul.sub-menu li a {
  color: #424244;
}

.fl-nav-icon-toggled .fl-page-nav-collapse ul.sub-menu li a:hover {
  color: #1d75bd;
}

.fl-nav-icon-toggled .fl-page-nav-collapse .fl-page-nav-search {
  display: none;
}

.fl-nav-icon-toggled .fl-page-nav ul.sub-menu {
  background-color: transparent;
  border: none;
  float: none;
  height: 100%;
  list-style: none;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: auto;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.fl-nav-icon-toggled .fl-page-nav ul.sub-menu ul.sub-menu {
  padding-bottom: 0;
}

.fl-nav-icon-toggled .fl-page-nav ul.sub-menu li {
  border-top: 1px solid #e6e6e6;
}

.fl-nav-icon-toggled .fl-page-nav ul.sub-menu li a {
  clear: both;
  color: #424244;
  display: block;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 15px 20px;
}

.fl-nav-icon-toggled .fl-page-nav ul.sub-menu li a:hover,
.fl-nav-icon-toggled .fl-page-nav ul.sub-menu li a:focus {
  color: #1d75bd;
  text-decoration: none;
}

.fl-nav-icon-toggled .fl-page-nav ul.sub-menu li a:before {
  content: '\f105';
  font-family: FontAwesome;
  margin-right: 10px;
}

.fl-nav-icon-toggled .fl-page-nav ul li.fl-mobile-sub-menu-open ul.sub-menu {
  position: static;
}

.fl-submenu-toggle .fl-nav-icon-toggled .fl-page-nav ul.navbar-nav .sub-menu {
  position: absolute;
}

.fl-submenu-toggle .fl-nav-icon-toggled .fl-page-nav ul.navbar-nav li.menu-item-has-children > a:after {
  content: '\f107';
  font-family: FontAwesome;
  float: right;
}

.fl-submenu-toggle .fl-nav-icon-toggled .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > a:after {
  content: '\f106';
  font-family: FontAwesome;
  float: right;
}

.fl-submenu-toggle .fl-nav-icon-toggled .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > ul.sub-menu {
  position: static;
}

.fl-submenu-toggle .fl-nav-icon-toggled .fl-page-nav ul.navbar-nav li a {
  padding: 15px;
}

.fl-nav-icon-toggled .fl-page-nav-wrap {
  border: 0 none;
  margin: 0;
  padding-bottom: 0;
}

.fl-nav-icon-toggled .fl-page-header-logo {
  text-align: left;
  margin-right: 55px;
}

.fl-nav-icon-toggled .fl-page-nav-col,
.fl-nav-icon-toggled .navbar {
  position: static;
}

.fl-nav-icon-toggled .fl-page-nav-container {
  position: unset;
}

.fl-nav-icon-toggled .fl-page-nav .navbar-toggle {
  background: transparent;
  display: block;
  font-size: 22px;
  line-height: 19px;
  padding: 6px 8px;
  position: absolute;
  right: 20px;
  top: -1px;
  width: auto;
  color: #424244;
}

.fl-nav-icon-toggled .fl-page-nav .navbar-toggle:hover,
.fl-nav-icon-toggled .fl-page-nav .navbar-toggle:focus {
  background: transparent;
}

.fl-nav-icon-toggled .fl-page-nav .navbar-toggle * {
  color: #424244;
}

.fl-nav-button-toggled.fl-page-nav-right .fl-page-nav-collapse ul.navbar-nav {
  float: none !important;
}

.fl-nav-button-toggled.fl-page-nav-right .fl-page-header-row .fl-page-header-logo {
  padding-bottom: 5px;
}

.fl-nav-button-toggled.fl-page-nav-left .fl-page-header-row .fl-page-header-logo {
  padding-bottom: 5px;
}

.fl-nav-button-toggled.fl-page-nav-left .fl-page-nav ul.navbar-nav > li:first-child a {
  padding-left: 17px;
}

.fl-nav-button-toggled.fl-page-nav-centered-inline-logo {
  text-align: left;
}

.fl-nav-button-toggled.fl-page-nav-centered-inline-logo .fl-page-header-logo {
  display: block;
}

.fl-nav-button-toggled.fl-page-header-vertical {
  top: 0;
}

.fl-nav-button-toggled .fl-page-nav-collapse {
  border-top: 1px solid #e6e6e6;
  padding-bottom: 10px;
}

.fl-nav-button-toggled .fl-page-nav-collapse.collapse {
  display: none !important;
  visibility: hidden !important;
}

.fl-nav-button-toggled .fl-page-nav-collapse.collapse.in {
  display: block !important;
  visibility: visible !important;
}

.fl-nav-button-toggled .fl-page-nav-collapse ul.navbar-nav {
  display: block;
  float: none;
}

.fl-nav-button-toggled .fl-page-nav-collapse ul.navbar-nav > li {
  border-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: block;
  float: none;
  position: relative;
}

.fl-nav-button-toggled .fl-page-nav-collapse ul.navbar-nav > li > a {
  color: #424244;
  text-align: left;
}

.fl-nav-button-toggled .fl-page-nav-collapse ul.navbar-nav > li > a:hover,
.fl-nav-button-toggled .fl-page-nav-collapse ul.navbar-nav > li > a:focus {
  color: #1d75bd;
}

.fl-nav-button-toggled .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item > a {
  color: #1d75bd;
}

.fl-nav-button-toggled .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item ~ li.current-menu-item > a {
  color: #424244;
}

.fl-nav-button-toggled .fl-page-nav-collapse ul.sub-menu li {
  border-color: #e6e6e6;
}

.fl-nav-button-toggled .fl-page-nav-collapse ul.sub-menu li a {
  color: #424244;
}

.fl-nav-button-toggled .fl-page-nav-collapse ul.sub-menu li a:hover {
  color: #1d75bd;
}

.fl-nav-button-toggled .fl-page-nav-collapse .fl-page-nav-search {
  display: none;
}

.fl-nav-button-toggled .fl-page-nav ul.sub-menu {
  background-color: transparent;
  border: none;
  float: none;
  height: 100%;
  list-style: none;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: auto;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.fl-nav-button-toggled .fl-page-nav ul.sub-menu ul.sub-menu {
  padding-bottom: 0;
}

.fl-nav-button-toggled .fl-page-nav ul.sub-menu li {
  border-top: 1px solid #e6e6e6;
}

.fl-nav-button-toggled .fl-page-nav ul.sub-menu li a {
  clear: both;
  color: #424244;
  display: block;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 15px 20px;
}

.fl-nav-button-toggled .fl-page-nav ul.sub-menu li a:hover,
.fl-nav-button-toggled .fl-page-nav ul.sub-menu li a:focus {
  color: #1d75bd;
  text-decoration: none;
}

.fl-nav-button-toggled .fl-page-nav ul.sub-menu li a:before {
  content: '\f105';
  font-family: FontAwesome;
  margin-right: 10px;
}

.fl-nav-button-toggled .fl-page-nav ul li.fl-mobile-sub-menu-open ul.sub-menu {
  position: static;
}

.fl-submenu-toggle .fl-nav-button-toggled .fl-page-nav ul.navbar-nav .sub-menu {
  position: absolute;
}

.fl-submenu-toggle .fl-nav-button-toggled .fl-page-nav ul.navbar-nav li.menu-item-has-children > a:after {
  content: '\f107';
  font-family: FontAwesome;
  float: right;
}

.fl-submenu-toggle .fl-nav-button-toggled .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > a:after {
  content: '\f106';
  font-family: FontAwesome;
  float: right;
}

.fl-submenu-toggle .fl-nav-button-toggled .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > ul.sub-menu {
  position: static;
}

.fl-submenu-toggle .fl-nav-button-toggled .fl-page-nav ul.navbar-nav li a {
  padding: 15px;
}

.fl-nav-button-toggled .fl-page-nav-wrap {
  border: 0 none;
  margin: 0;
  padding-bottom: 20px;
}

.fl-nav-button-toggled .fl-page-nav .navbar-toggle {
  display: block;
  background: #e6e6e6;
  color: #424244;
}

.fl-nav-button-toggled .fl-page-nav .navbar-toggle * {
  color: #424244;
}

.fl-nav-button-toggled .fl-page-nav .navbar-toggle:hover,
.fl-nav-button-toggled .fl-page-nav .navbar-toggle:focus {
  background: #e6e6e6;
}

.fl-nav-button-toggled.fl-page-header-fixed .fl-page-nav-wrap {
  padding-top: 20px;
}

.fl-page-nav-toggled-common.fl-page-nav-right .fl-page-nav-collapse ul.navbar-nav {
  float: none !important;
}

.fl-page-nav-toggled-common.fl-page-nav-right .fl-page-header-row .fl-page-header-logo {
  padding-bottom: 5px;
}

.fl-page-nav-toggled-common.fl-page-nav-left .fl-page-header-row .fl-page-header-logo {
  padding-bottom: 5px;
}

.fl-page-nav-toggled-common.fl-page-nav-left .fl-page-nav ul.navbar-nav > li:first-child a {
  padding-left: 17px;
}

.fl-page-nav-toggled-common.fl-page-nav-centered-inline-logo {
  text-align: left;
}

.fl-page-nav-toggled-common.fl-page-nav-centered-inline-logo .fl-page-header-logo {
  display: block;
}

.fl-page-nav-toggled-common.fl-page-header-vertical {
  top: 0;
}

.fl-page-nav-toggled-common .fl-page-nav-collapse {
  border-top: 1px solid #e6e6e6;
  padding-bottom: 10px;
}

.fl-page-nav-toggled-common .fl-page-nav-collapse.collapse {
  display: none !important;
  visibility: hidden !important;
}

.fl-page-nav-toggled-common .fl-page-nav-collapse.collapse.in {
  display: block !important;
  visibility: visible !important;
}

.fl-page-nav-toggled-common .fl-page-nav-collapse ul.navbar-nav {
  display: block;
  float: none;
}

.fl-page-nav-toggled-common .fl-page-nav-collapse ul.navbar-nav > li {
  border-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: block;
  float: none;
  position: relative;
}

.fl-page-nav-toggled-common .fl-page-nav-collapse ul.navbar-nav > li > a {
  color: #424244;
  text-align: left;
}

.fl-page-nav-toggled-common .fl-page-nav-collapse ul.navbar-nav > li > a:hover,
.fl-page-nav-toggled-common .fl-page-nav-collapse ul.navbar-nav > li > a:focus {
  color: #1d75bd;
}

.fl-page-nav-toggled-common .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item > a {
  color: #1d75bd;
}

.fl-page-nav-toggled-common .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item ~ li.current-menu-item > a {
  color: #424244;
}

.fl-page-nav-toggled-common .fl-page-nav-collapse ul.sub-menu li {
  border-color: #e6e6e6;
}

.fl-page-nav-toggled-common .fl-page-nav-collapse ul.sub-menu li a {
  color: #424244;
}

.fl-page-nav-toggled-common .fl-page-nav-collapse ul.sub-menu li a:hover {
  color: #1d75bd;
}

.fl-page-nav-toggled-common .fl-page-nav-collapse .fl-page-nav-search {
  display: none;
}

.fl-page-nav-toggled-common .fl-page-nav ul.sub-menu {
  background-color: transparent;
  border: none;
  float: none;
  height: 100%;
  list-style: none;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: auto;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.fl-page-nav-toggled-common .fl-page-nav ul.sub-menu ul.sub-menu {
  padding-bottom: 0;
}

.fl-page-nav-toggled-common .fl-page-nav ul.sub-menu li {
  border-top: 1px solid #e6e6e6;
}

.fl-page-nav-toggled-common .fl-page-nav ul.sub-menu li a {
  clear: both;
  color: #424244;
  display: block;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 15px 20px;
}

.fl-page-nav-toggled-common .fl-page-nav ul.sub-menu li a:hover,
.fl-page-nav-toggled-common .fl-page-nav ul.sub-menu li a:focus {
  color: #1d75bd;
  text-decoration: none;
}

.fl-page-nav-toggled-common .fl-page-nav ul.sub-menu li a:before {
  content: '\f105';
  font-family: FontAwesome;
  margin-right: 10px;
}

.fl-page-nav-toggled-common .fl-page-nav ul li.fl-mobile-sub-menu-open ul.sub-menu {
  position: static;
}

.fl-submenu-toggle .fl-page-nav-toggled-common .fl-page-nav ul.navbar-nav .sub-menu {
  position: absolute;
}

.fl-submenu-toggle .fl-page-nav-toggled-common .fl-page-nav ul.navbar-nav li.menu-item-has-children > a:after {
  content: '\f107';
  font-family: FontAwesome;
  float: right;
}

.fl-submenu-toggle .fl-page-nav-toggled-common .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > a:after {
  content: '\f106';
  font-family: FontAwesome;
  float: right;
}

.fl-submenu-toggle .fl-page-nav-toggled-common .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > ul.sub-menu {
  position: static;
}

.fl-submenu-toggle .fl-page-nav-toggled-common .fl-page-nav ul.navbar-nav li a {
  padding: 15px;
}

.fl-page-header-layouts-toggled.fl-page-nav-right .fl-page-nav-collapse ul.navbar-nav {
  float: none !important;
}

.fl-page-header-layouts-toggled.fl-page-nav-right .fl-page-header-row .fl-page-header-logo {
  padding-bottom: 5px;
}

.fl-page-header-layouts-toggled.fl-page-nav-left .fl-page-header-row .fl-page-header-logo {
  padding-bottom: 5px;
}

.fl-page-header-layouts-toggled.fl-page-nav-left .fl-page-nav ul.navbar-nav > li:first-child a {
  padding-left: 17px;
}

.fl-page-header-layouts-toggled.fl-page-nav-centered-inline-logo {
  text-align: left;
}

.fl-page-header-layouts-toggled.fl-page-nav-centered-inline-logo .fl-page-header-logo {
  display: block;
}

.fl-page-header-layouts-toggled.fl-page-header-vertical {
  top: 0;
}

body {
  background-color: #f2f2f2;
  color: #424244;
  font-family: Helvetica, Verdana, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  padding: 0;
}

body.modal-open .media-modal-close:hover,
body.modal-open .media-modal-close:focus {
  background: none;
  border: none;
  padding: 1px;
}

body.modal-open .media-modal-close:focus {
  position: absolute;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #424244;
  font-family: Helvetica, Verdana, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.4;
  text-transform: none;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #424244;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  text-decoration: none;
}

h1 {
  font-size: 36px;
  line-height: 1.4;
  letter-spacing: 0px;
}

h2 {
  font-size: 30px;
  line-height: 1.4;
  letter-spacing: 0px;
}

h3 {
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: 0px;
}

h4 {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0px;
}

h5 {
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0px;
}

h6 {
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0px;
}

a {
  color: #1d75bd;
}

a:hover,
a:focus {
  color: #428bca;
}

blockquote {
  border-color: #e6e6e6;
}

iframe {
  max-width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
}

.fl-page {
  position: relative;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0 auto;
}

.fl-page-bar {
  border-bottom: 1px solid #e6e6e6;
  font-size: 12px;
  line-height: 14px;
  border-color: #e6e6e6;
  background-color: #ffffff;
  color: #000000;
}

.fl-page-bar *,
.fl-page-bar h1,
.fl-page-bar h2,
.fl-page-bar h3,
.fl-page-bar h4,
.fl-page-bar h5,
.fl-page-bar h6 {
  color: #000000;
}

.fl-page-bar a,
.fl-page-bar a *,
.fl-page-bar a.fa {
  color: #428bca;
}

.fl-page-bar a:hover,
.fl-page-bar a:focus,
.fl-page-bar a:hover *,
.fl-page-bar a:focus *,
.fl-page-bar a.fa:hover,
.fl-page-bar a.fa:focus {
  color: #428bca;
}

.fl-page-bar .navbar-nav li > a {
  color: #428bca;
}

.fl-page-bar .navbar-nav li > a:hover,
.fl-page-bar .navbar-nav li > a:focus {
  color: #428bca;
}

.fl-page-bar .navbar-nav li.current-menu-ancestor > a,
.fl-page-bar .navbar-nav li.current-menu-parent > a,
.fl-page-bar .navbar-nav li.current-menu-item > a {
  color: #428bca;
}

.fl-page-bar .navbar-nav li.current-menu-item ~ li.current-menu-item > a {
  color: #000000;
}

.fl-page-bar-container {
  padding-bottom: 5px;
  padding-top: 5px;
}

.fl-page-bar-nav {
  float: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.fl-page-bar-nav > li {
  display: inline-block;
  float: none;
  position: relative;
}

.fl-page-bar-nav > li > a {
  padding: 2px 10px;
}

.fl-page-bar-nav > li > a:hover,
.fl-page-bar-nav > li > a:focus {
  background: transparent;
}

.fl-page-bar-nav > li > ul.sub-menu {
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-color: 'transparent';
  background-color: #ffffff;
  color: #000000;
  left: -99999px;
  padding: 7px 13px;
  position: absolute;
  top: 100%;
  width: 200px;
  z-index: 1000;
}

.fl-page-bar-nav > li > ul.sub-menu *,
.fl-page-bar-nav > li > ul.sub-menu h1,
.fl-page-bar-nav > li > ul.sub-menu h2,
.fl-page-bar-nav > li > ul.sub-menu h3,
.fl-page-bar-nav > li > ul.sub-menu h4,
.fl-page-bar-nav > li > ul.sub-menu h5,
.fl-page-bar-nav > li > ul.sub-menu h6 {
  color: #000000;
}

.fl-page-bar-nav > li > ul.sub-menu a,
.fl-page-bar-nav > li > ul.sub-menu a *,
.fl-page-bar-nav > li > ul.sub-menu a.fa {
  color: #428bca;
}

.fl-page-bar-nav > li > ul.sub-menu a:hover,
.fl-page-bar-nav > li > ul.sub-menu a:focus,
.fl-page-bar-nav > li > ul.sub-menu a:hover *,
.fl-page-bar-nav > li > ul.sub-menu a:focus *,
.fl-page-bar-nav > li > ul.sub-menu a.fa:hover,
.fl-page-bar-nav > li > ul.sub-menu a.fa:focus {
  color: #428bca;
}

.fl-page-bar-nav > li > ul.sub-menu .navbar-nav li > a {
  color: #428bca;
}

.fl-page-bar-nav > li > ul.sub-menu .navbar-nav li > a:hover,
.fl-page-bar-nav > li > ul.sub-menu .navbar-nav li > a:focus {
  color: #428bca;
}

.fl-page-bar-nav > li > ul.sub-menu .navbar-nav li.current-menu-ancestor > a,
.fl-page-bar-nav > li > ul.sub-menu .navbar-nav li.current-menu-parent > a,
.fl-page-bar-nav > li > ul.sub-menu .navbar-nav li.current-menu-item > a {
  color: #428bca;
}

.fl-page-bar-nav > li > ul.sub-menu .navbar-nav li.current-menu-item ~ li.current-menu-item > a {
  color: #000000;
}

.fl-page-bar-nav > li > ul.sub-menu li {
  display: block;
  list-style: none;
  text-align: left;
}

.fl-page-bar-nav > li > ul.sub-menu li a {
  display: block;
  padding: 5px 0;
}

.fl-page-bar-nav > li > ul.sub-menu li a:hover {
  text-decoration: none;
}

.fl-page-bar-nav > li > ul.sub-menu li ul.sub-menu {
  padding: 0;
}

.fl-page-bar-nav > li.fl-sub-menu-right ul.sub-menu {
  right: 0;
}

.fl-page-bar-nav > li:hover > ul.sub-menu {
  left: auto;
}

.fl-page-bar-text {
  padding: 5px 0;
  text-align: center;
}

.fl-page-bar .fl-social-icons {
  text-align: center;
}

.fl-submenu-indicator .fl-page-bar ul.fl-page-bar-nav > li.menu-item-has-children > a:after {
  content: '\f107';
  font-family: FontAwesome;
  float: right;
  margin-left: 5px;
}

.fl-submenu-indicator .fl-page-bar ul.fl-page-bar-nav > li > ul.sub-menu li ul.sub-menu ul.sub-menu {
  margin-left: 5px;
}

.fl-submenu-indicator .fl-page-bar ul.fl-page-bar-nav > li > ul.sub-menu li ul.sub-menu li a {
  padding: 5px;
}

.fl-submenu-indicator .fl-page-bar ul.fl-page-bar-nav > li > ul.sub-menu li ul.sub-menu li a:before {
  content: '\f105';
  font-family: FontAwesome;
  margin-right: 5px;
}

.fl-page-header {
  border-color: #e6e6e6;
  background-color: #ffffff;
  color: #424244;
}

.fl-page-header *,
.fl-page-header h1,
.fl-page-header h2,
.fl-page-header h3,
.fl-page-header h4,
.fl-page-header h5,
.fl-page-header h6 {
  color: #424244;
}

.fl-page-header a,
.fl-page-header a *,
.fl-page-header a.fa {
  color: #424244;
}

.fl-page-header a:hover,
.fl-page-header a:focus,
.fl-page-header a:hover *,
.fl-page-header a:focus *,
.fl-page-header a.fa:hover,
.fl-page-header a.fa:focus {
  color: #1d75bd;
}

.fl-page-header .navbar-nav li > a {
  color: #424244;
}

.fl-page-header .navbar-nav li > a:hover,
.fl-page-header .navbar-nav li > a:focus {
  color: #1d75bd;
}

.fl-page-header .navbar-nav li.current-menu-ancestor > a,
.fl-page-header .navbar-nav li.current-menu-parent > a,
.fl-page-header .navbar-nav li.current-menu-item > a {
  color: #1d75bd;
}

.fl-page-header .navbar-nav li.current-menu-item ~ li.current-menu-item > a {
  color: #424244;
}

.fl-page-header-container {
  padding-bottom: 5px;
  padding-top: 5px;
}

.fl-page-header-logo {
  text-align: center;
}

.fl-page-header-logo a {
  text-decoration: none;
}

.fl-logo-text {
  font-family: Helvetica, Verdana, Arial, sans-serif;
  font-weight: 400;
  font-size: 30px;
}

.fl-logo-img {
  max-width: 100%;
}

.fl-page-header-primary .fl-logo-img {
  height: auto !important;
}

.fl-page-nav-right.fl-page-header-fixed,
.fl-page-header-fixed {
  display: none;
  left: 0;
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 100;
  border-color: #e6e6e6;
  background-color: #ffffff;
  color: #424244;
  -moz-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
}

.fl-page-nav-right.fl-page-header-fixed *,
.fl-page-nav-right.fl-page-header-fixed h1,
.fl-page-nav-right.fl-page-header-fixed h2,
.fl-page-nav-right.fl-page-header-fixed h3,
.fl-page-nav-right.fl-page-header-fixed h4,
.fl-page-nav-right.fl-page-header-fixed h5,
.fl-page-nav-right.fl-page-header-fixed h6,
.fl-page-header-fixed *,
.fl-page-header-fixed h1,
.fl-page-header-fixed h2,
.fl-page-header-fixed h3,
.fl-page-header-fixed h4,
.fl-page-header-fixed h5,
.fl-page-header-fixed h6 {
  color: #424244;
}

.fl-page-nav-right.fl-page-header-fixed a,
.fl-page-nav-right.fl-page-header-fixed a *,
.fl-page-nav-right.fl-page-header-fixed a.fa,
.fl-page-header-fixed a,
.fl-page-header-fixed a *,
.fl-page-header-fixed a.fa {
  color: #424244;
}

.fl-page-nav-right.fl-page-header-fixed a:hover,
.fl-page-nav-right.fl-page-header-fixed a:focus,
.fl-page-nav-right.fl-page-header-fixed a:hover *,
.fl-page-nav-right.fl-page-header-fixed a:focus *,
.fl-page-nav-right.fl-page-header-fixed a.fa:hover,
.fl-page-nav-right.fl-page-header-fixed a.fa:focus,
.fl-page-header-fixed a:hover,
.fl-page-header-fixed a:focus,
.fl-page-header-fixed a:hover *,
.fl-page-header-fixed a:focus *,
.fl-page-header-fixed a.fa:hover,
.fl-page-header-fixed a.fa:focus {
  color: #1d75bd;
}

.fl-page-nav-right.fl-page-header-fixed .navbar-nav li > a,
.fl-page-header-fixed .navbar-nav li > a {
  color: #424244;
}

.fl-page-nav-right.fl-page-header-fixed .navbar-nav li > a:hover,
.fl-page-nav-right.fl-page-header-fixed .navbar-nav li > a:focus,
.fl-page-header-fixed .navbar-nav li > a:hover,
.fl-page-header-fixed .navbar-nav li > a:focus {
  color: #1d75bd;
}

.fl-page-nav-right.fl-page-header-fixed .navbar-nav li.current-menu-ancestor > a,
.fl-page-nav-right.fl-page-header-fixed .navbar-nav li.current-menu-parent > a,
.fl-page-nav-right.fl-page-header-fixed .navbar-nav li.current-menu-item > a,
.fl-page-header-fixed .navbar-nav li.current-menu-ancestor > a,
.fl-page-header-fixed .navbar-nav li.current-menu-parent > a,
.fl-page-header-fixed .navbar-nav li.current-menu-item > a {
  color: #1d75bd;
}

.fl-page-nav-right.fl-page-header-fixed .navbar-nav li.current-menu-item ~ li.current-menu-item > a,
.fl-page-header-fixed .navbar-nav li.current-menu-item ~ li.current-menu-item > a {
  color: #424244;
}

.fl-page-nav-right.fl-page-header-fixed .fl-page-header-container.container,
.fl-page-header-fixed .fl-page-header-container.container {
  padding-bottom: 8px;
  padding-top: 8px;
}

.fl-page-nav-right.fl-page-header-fixed.fl-page-header.fl-page-nav-toggle-visible-always .fl-page-header-row .fl-page-header-logo,
.fl-page-header-fixed.fl-page-header.fl-page-nav-toggle-visible-always .fl-page-header-row .fl-page-header-logo {
  padding-bottom: 0;
}

.fl-page-nav-right.fl-page-header-fixed.fl-page-header.fl-page-nav-toggle-visible-always .fl-page-nav-collapse,
.fl-page-header-fixed.fl-page-header.fl-page-nav-toggle-visible-always .fl-page-nav-collapse {
  margin-top: 20px;
}

.fl-page-nav-right.fl-page-header-fixed.fl-page-header.fl-page-nav-toggle-visible-always .fl-page-nav .navbar-toggle,
.fl-page-header-fixed.fl-page-header.fl-page-nav-toggle-visible-always .fl-page-nav .navbar-toggle {
  top: 15px;
}

.fl-page-nav-right.fl-page-header-fixed .fl-logo-img,
.fl-page-header-fixed .fl-logo-img {
  max-height: 46px !important;
  width: auto !important;
}

.fl-page-nav-right.fl-page-header-fixed .fl-logo-text,
.fl-page-header-fixed .fl-logo-text {
  font-size: 28px;
}

.fl-page-nav-right.fl-page-header-fixed .fl-page-nav-wrap .navbar-nav > li > a,
.fl-page-header-fixed .fl-page-nav-wrap .navbar-nav > li > a {
  padding: 10px 15px;
}

.fl-page-nav-right.fl-page-header-fixed .fl-page-nav ul.sub-menu,
.fl-page-header-fixed .fl-page-nav ul.sub-menu {
  background-color: #ffffff;
}

.admin-bar .fl-page-nav-right.fl-page-header-fixed,
.admin-bar .fl-page-header-fixed,
.admin-bar .fl-page-header-vertical {
  top: 32px;
}

.fl-page-nav-wrap {
  padding-bottom: 20px;
}

.fl-page-nav {
  background: transparent;
  border: none;
  min-height: 0;
  margin-bottom: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.fl-page-nav .navbar-nav > li > a {
  padding: 15px;
}

.fl-page-nav .navbar-toggle {
  border: none;
  float: none;
  font-family: Helvetica, Verdana, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  text-transform: none;
  margin: 0;
  width: 100%;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.fl-page-nav .navbar-toggle:hover,
.fl-page-nav .navbar-toggle:focus {
  outline: none;
}

.fl-page-nav .navbar-nav,
.fl-page-nav .navbar-nav a {
  font-family: Helvetica, Verdana, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  text-transform: none;
}

.fl-full-width .fl-page-nav {
  margin: 0 auto;
}

.fl-page-nav .mega-menu a[href="#"]:hover,
.fl-page-nav .mega-menu a[href="#"]:focus {
  color: #424244 !important;
  cursor: default;
}

.fl-page-header[class*=" fl-page-nav-toggle-visible"] ul.navbar-nav li.mega-menu .hide-heading > a {
  display: none;
}

.mega-menu-spacer {
  background: none;
  display: block;
  left: 0;
  position: absolute;
  width: 100%;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always {
  position: relative;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always.fl-page-nav-right .fl-page-nav-collapse ul.navbar-nav {
  float: none !important;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always.fl-page-nav-right .fl-page-header-row .fl-page-header-logo {
  padding-bottom: 5px;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always.fl-page-nav-left .fl-page-header-row .fl-page-header-logo {
  padding-bottom: 5px;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always.fl-page-nav-left .fl-page-nav ul.navbar-nav > li:first-child a {
  padding-left: 17px;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always.fl-page-nav-centered-inline-logo {
  text-align: left;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always.fl-page-nav-centered-inline-logo .fl-page-header-logo {
  display: block;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always.fl-page-header-vertical {
  top: 0;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-collapse {
  border-top: 1px solid #e6e6e6;
  padding-bottom: 10px;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-collapse.collapse {
  display: none !important;
  visibility: hidden !important;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-collapse.collapse.in {
  display: block !important;
  visibility: visible !important;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.navbar-nav {
  display: block;
  float: none;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.navbar-nav > li {
  border-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: block;
  float: none;
  position: relative;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.navbar-nav > li > a {
  color: #424244;
  text-align: left;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.navbar-nav > li > a:hover,
.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.navbar-nav > li > a:focus {
  color: #1d75bd;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item > a {
  color: #1d75bd;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item ~ li.current-menu-item > a {
  color: #424244;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.sub-menu li {
  border-color: #e6e6e6;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.sub-menu li a {
  color: #424244;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.sub-menu li a:hover {
  color: #1d75bd;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-collapse .fl-page-nav-search {
  display: none;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav ul.sub-menu {
  background-color: transparent;
  border: none;
  float: none;
  height: 100%;
  list-style: none;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: auto;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav ul.sub-menu ul.sub-menu {
  padding-bottom: 0;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav ul.sub-menu li {
  border-top: 1px solid #e6e6e6;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav ul.sub-menu li a {
  clear: both;
  color: #424244;
  display: block;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 15px 20px;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav ul.sub-menu li a:hover,
.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav ul.sub-menu li a:focus {
  color: #1d75bd;
  text-decoration: none;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav ul.sub-menu li a:before {
  content: '\f105';
  font-family: FontAwesome;
  margin-right: 10px;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav ul li.fl-mobile-sub-menu-open ul.sub-menu {
  position: static;
}

.fl-submenu-toggle .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav ul.navbar-nav .sub-menu {
  position: absolute;
}

.fl-submenu-toggle .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav ul.navbar-nav li.menu-item-has-children > a:after {
  content: '\f107';
  font-family: FontAwesome;
  float: right;
}

.fl-submenu-toggle .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > a:after {
  content: '\f106';
  font-family: FontAwesome;
  float: right;
}

.fl-submenu-toggle .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > ul.sub-menu {
  position: static;
}

.fl-submenu-toggle .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav ul.navbar-nav li a {
  padding: 15px;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-wrap {
  border: 0 none;
  margin: 0;
  padding-bottom: 0;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-header-logo {
  text-align: left;
  margin-right: 55px;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-col,
.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .navbar {
  position: static;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav-container {
  position: unset;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav .navbar-toggle {
  background: transparent;
  display: block;
  font-size: 22px;
  line-height: 19px;
  padding: 6px 8px;
  position: absolute;
  right: 20px;
  top: -1px;
  width: auto;
  color: #424244;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav .navbar-toggle:hover,
.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav .navbar-toggle:focus {
  background: transparent;
}

.fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-always .fl-page-nav .navbar-toggle * {
  color: #424244;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always.fl-page-nav-right .fl-page-nav-collapse ul.navbar-nav {
  float: none !important;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always.fl-page-nav-right .fl-page-header-row .fl-page-header-logo {
  padding-bottom: 5px;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always.fl-page-nav-left .fl-page-header-row .fl-page-header-logo {
  padding-bottom: 5px;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always.fl-page-nav-left .fl-page-nav ul.navbar-nav > li:first-child a {
  padding-left: 17px;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always.fl-page-nav-centered-inline-logo {
  text-align: left;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always.fl-page-nav-centered-inline-logo .fl-page-header-logo {
  display: block;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always.fl-page-header-vertical {
  top: 0;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav-collapse {
  border-top: 1px solid #e6e6e6;
  padding-bottom: 10px;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav-collapse.collapse {
  display: none !important;
  visibility: hidden !important;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav-collapse.collapse.in {
  display: block !important;
  visibility: visible !important;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.navbar-nav {
  display: block;
  float: none;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.navbar-nav > li {
  border-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: block;
  float: none;
  position: relative;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.navbar-nav > li > a {
  color: #424244;
  text-align: left;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.navbar-nav > li > a:hover,
.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.navbar-nav > li > a:focus {
  color: #1d75bd;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item > a {
  color: #1d75bd;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item ~ li.current-menu-item > a {
  color: #424244;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.sub-menu li {
  border-color: #e6e6e6;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.sub-menu li a {
  color: #424244;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav-collapse ul.sub-menu li a:hover {
  color: #1d75bd;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav-collapse .fl-page-nav-search {
  display: none;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav ul.sub-menu {
  background-color: transparent;
  border: none;
  float: none;
  height: 100%;
  list-style: none;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: auto;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav ul.sub-menu ul.sub-menu {
  padding-bottom: 0;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav ul.sub-menu li {
  border-top: 1px solid #e6e6e6;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav ul.sub-menu li a {
  clear: both;
  color: #424244;
  display: block;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 15px 20px;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav ul.sub-menu li a:hover,
.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav ul.sub-menu li a:focus {
  color: #1d75bd;
  text-decoration: none;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav ul.sub-menu li a:before {
  content: '\f105';
  font-family: FontAwesome;
  margin-right: 10px;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav ul li.fl-mobile-sub-menu-open ul.sub-menu {
  position: static;
}

.fl-submenu-toggle .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav ul.navbar-nav .sub-menu {
  position: absolute;
}

.fl-submenu-toggle .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav ul.navbar-nav li.menu-item-has-children > a:after {
  content: '\f107';
  font-family: FontAwesome;
  float: right;
}

.fl-submenu-toggle .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > a:after {
  content: '\f106';
  font-family: FontAwesome;
  float: right;
}

.fl-submenu-toggle .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > ul.sub-menu {
  position: static;
}

.fl-submenu-toggle .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav ul.navbar-nav li a {
  padding: 15px;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav-wrap {
  border: 0 none;
  margin: 0;
  padding-bottom: 20px;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav .navbar-toggle {
  display: block;
  background: #e6e6e6;
  color: #424244;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav .navbar-toggle * {
  color: #424244;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav .navbar-toggle:hover,
.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always .fl-page-nav .navbar-toggle:focus {
  background: #e6e6e6;
}

.fl-page-nav-toggle-button.fl-page-nav-toggle-visible-always.fl-page-header-fixed .fl-page-nav-wrap {
  padding-top: 20px;
}

.fl-page-nav-collapse {
  border: none;
  max-height: none;
  padding: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.fl-page-nav-collapse ul.navbar-nav {
  margin: 0;
}

.fl-page-nav-collapse ul.navbar-nav > li {
  border-bottom: 1px solid #e6e6e6;
}

.fl-page-nav-collapse ul.navbar-nav > li:last-child {
  border-bottom: none;
}

.fl-page-nav-collapse.in {
  overflow-y: visible;
}

.fl-page-nav-search {
  bottom: 0;
  display: none;
  position: absolute;
  right: 0;
  top: 0;
}

.fl-page-nav-search a.fa-search {
  color: #428bca;
  font-size: 20px;
  padding: 8px;
  position: absolute;
  right: 0;
  top: 5px;
}

.fl-page-nav-search a.fa-search:hover,
.fl-page-nav-search a.fa-search:focus {
  color: #428bca;
  text-decoration: none;
}

.fl-page-nav-search form {
  background-color: #ffffff;
  border-top: 3px solid #a1c5e5;
  display: none;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 100%;
  width: 400px;
  z-index: 1000;
  -moz-box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15);
}

.fl-page-nav-search form input.fl-search-input {
  background-color: #ffffff;
  border-color: #e6e6e6;
  color: #428bca;
}

.fl-page-nav-search form input.fl-search-input:focus {
  color: #428bca;
}

.fl-page-nav ul.sub-menu {
  background-clip: padding-box;
  background-color: #ffffff;
  border-top: 3px solid #a1c5e5;
  font-size: 14px;
  float: none;
  left: -99999px;
  list-style: none;
  padding: 10px 0;
  position: absolute;
  top: 100%;
  width: 240px;
  z-index: 1000;
  -moz-box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15);
}

.fl-page-nav ul.sub-menu ul.sub-menu {
  left: -99999px;
  padding-bottom: 10px;
  top: -13px;
}

.fl-page-nav ul.sub-menu li {
  border: none;
}

.fl-page-nav ul.sub-menu li a {
  clear: none;
  color: #428bca;
  display: block;
  font-weight: 400;
  line-height: 20px;
  margin: 0 10px;
  padding: 10px 15px;
}

.fl-page-nav ul.sub-menu li a:hover,
.fl-page-nav ul.sub-menu li a:focus {
  color: #428bca;
  text-decoration: none;
}

.fl-page-nav ul.sub-menu li a:before {
  content: '';
  margin-right: 0;
}

.fl-page-nav ul li {
  position: relative;
}

.fl-page-nav ul li.fl-sub-menu-open > ul.sub-menu {
  left: auto;
}

.fl-page-nav ul li.fl-sub-menu-open > ul.sub-menu li.fl-sub-menu-open > ul.sub-menu {
  left: 100%;
}

.fl-page-nav ul li.fl-sub-menu-open > ul.sub-menu li.fl-sub-menu-open.fl-sub-menu-right > ul.sub-menu {
  left: auto;
  right: 100%;
}

.fl-page-nav ul li.fl-sub-menu-open.fl-sub-menu-right > ul.sub-menu {
  right: 0;
}

.fl-page-nav-bottom .fl-page-header-content {
  text-align: center;
}

.fl-page-nav-bottom .fl-page-header-content .fl-page-header-text {
  font-size: 16px;
  margin: 25px 0 0;
}

.fl-page-nav-bottom .fl-page-header-content .fl-social-icons {
  margin: 15px 0 0;
}

.fl-page-nav-bottom .fl-page-header-content .fl-social-icons .fl-icon {
  margin: 0 5px 10px;
}

.fl-page-nav-right .fl-page-header-container {
  padding-bottom: 0;
}

.fl-page-nav-right .fl-page-header-logo {
  padding-bottom: 5px;
}

.fl-page-nav-right .fl-page-nav-search {
  float: right;
  position: relative;
}

.fl-page-nav-right .fl-page-nav-search a.fa-search {
  padding: 13px 0 13px 15px;
  position: static;
}

.fl-page-header-vertical .fl-page-header-container {
  padding-bottom: 0;
}

.fl-page-header-vertical .fl-page-header-logo {
  padding-bottom: 5px;
}

.fl-page-nav-left .fl-page-header-container {
  padding-bottom: 0;
}

.fl-page-nav-left .fl-page-header-logo {
  padding-bottom: 5px;
}

.fl-page-nav-left .fl-page-nav-search {
  float: left;
  position: relative;
}

.fl-page-nav-left .fl-page-nav-search a.fa-search {
  padding: 13px 20px 13px 0;
  position: static;
}

.fl-page-nav-centered-inline-logo .fl-page-header-container {
  padding-bottom: 0;
}

.fl-page-nav-centered-inline-logo .fl-page-header-logo {
  padding-bottom: 5px;
}

.fl-page-content {
  background-color: #ffffff;
}

.fl-content,
.fl-sidebar {
  margin: 20px 0;
}

.fl-sidebar {
  display: none;
}

.fl-sidebar.fl-sidebar-display-always {
  display: block;
}

.fl-page-footer-widgets {
  border-top: 1px solid #e6e6e6;
  border-color: #e6e6e6;
  background-color: #ffffff;
  color: #000000;
}

.fl-page-footer-widgets *,
.fl-page-footer-widgets h1,
.fl-page-footer-widgets h2,
.fl-page-footer-widgets h3,
.fl-page-footer-widgets h4,
.fl-page-footer-widgets h5,
.fl-page-footer-widgets h6 {
  color: #000000;
}

.fl-page-footer-widgets a,
.fl-page-footer-widgets a *,
.fl-page-footer-widgets a.fa {
  color: #428bca;
}

.fl-page-footer-widgets a:hover,
.fl-page-footer-widgets a:focus,
.fl-page-footer-widgets a:hover *,
.fl-page-footer-widgets a:focus *,
.fl-page-footer-widgets a.fa:hover,
.fl-page-footer-widgets a.fa:focus {
  color: #428bca;
}

.fl-page-footer-widgets .navbar-nav li > a {
  color: #428bca;
}

.fl-page-footer-widgets .navbar-nav li > a:hover,
.fl-page-footer-widgets .navbar-nav li > a:focus {
  color: #428bca;
}

.fl-page-footer-widgets .navbar-nav li.current-menu-ancestor > a,
.fl-page-footer-widgets .navbar-nav li.current-menu-parent > a,
.fl-page-footer-widgets .navbar-nav li.current-menu-item > a {
  color: #428bca;
}

.fl-page-footer-widgets .navbar-nav li.current-menu-item ~ li.current-menu-item > a {
  color: #000000;
}

.fl-page-footer-widgets-container {
  padding-top: 30px;
}

.fl-page-footer {
  border-top: 1px solid #e6e6e6;
  font-size: 15px;
  border-color: #e6e6e6;
  background-color: #ffffff;
  color: #000000;
}

.fl-page-footer *,
.fl-page-footer h1,
.fl-page-footer h2,
.fl-page-footer h3,
.fl-page-footer h4,
.fl-page-footer h5,
.fl-page-footer h6 {
  color: #000000;
}

.fl-page-footer a,
.fl-page-footer a *,
.fl-page-footer a.fa {
  color: #428bca;
}

.fl-page-footer a:hover,
.fl-page-footer a:focus,
.fl-page-footer a:hover *,
.fl-page-footer a:focus *,
.fl-page-footer a.fa:hover,
.fl-page-footer a.fa:focus {
  color: #428bca;
}

.fl-page-footer .navbar-nav li > a {
  color: #428bca;
}

.fl-page-footer .navbar-nav li > a:hover,
.fl-page-footer .navbar-nav li > a:focus {
  color: #428bca;
}

.fl-page-footer .navbar-nav li.current-menu-ancestor > a,
.fl-page-footer .navbar-nav li.current-menu-parent > a,
.fl-page-footer .navbar-nav li.current-menu-item > a {
  color: #428bca;
}

.fl-page-footer .navbar-nav li.current-menu-item ~ li.current-menu-item > a {
  color: #000000;
}

.fl-page-footer-container {
  padding-top: 20px;
}

.fl-page-footer-text {
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: center;
}

.fl-page-footer .fl-social-icons {
  text-align: center;
}

.fl-page-footer .fl-social-icons .fl-icon {
  margin: 0 5px 20px 5px;
}

.fl-page-footer-nav {
  float: none;
  text-align: center;
  margin: 0 0 10px;
}

.fl-page-footer-nav > li {
  display: inline-block;
  float: none;
}

.fl-page-footer-nav ul.sub-menu {
  display: none;
}

.fl-page-footer-nav > li > a {
  padding-top: 0;
}

.fl-page-footer-nav > li > a:hover,
.fl-page-footer-nav > li > a:focus {
  background: transparent;
}

#fl-to-top {
  display: none;
  background-color: #ccc;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 44px;
  height: 42px;
  text-align: center;
  z-index: 1003;
}

#fl-to-top:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

#fl-to-top i {
  position: absolute;
  top: 12px;
  left: 13px;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
}

.fl-social-icons .fl-icon {
  display: inline-block;
  font-family: 'Mono Social Icons Font';
  font-size: 28px;
  height: 24px;
  line-height: 24px;
  text-decoration: none;
  -webkit-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  width: 24px;
}

.fl-icon-facebook-circle:before {
  content: '\e227';
}

.fl-icon-facebook-regular:before {
  content: '\e027';
}

.fl-icon-facebook.fl-icon-color-branded {
  color: #3b5997;
}

.fl-icon-twitter-circle:before {
  content: '\e286';
}

.fl-icon-twitter-regular:before {
  content: '\e086';
}

.fl-icon-twitter.fl-icon-color-branded {
  color: #00aaef;
}

.fl-icon-google-circle:before {
  content: '\e239';
}

.fl-icon-google-regular:before {
  content: '\e039';
}

.fl-icon-google.fl-icon-color-branded {
  color: #d13e2b;
}

.fl-icon-linkedin-circle:before {
  content: '\e252';
}

.fl-icon-linkedin-regular:before {
  content: '\e052';
}

.fl-icon-linkedin.fl-icon-color-branded {
  color: #007ab8;
}

.fl-icon-yelp-circle:before {
  content: '\e298';
}

.fl-icon-yelp-regular:before {
  content: '\e098';
}

.fl-icon-yelp.fl-icon-color-branded {
  color: #c31200;
}

.fl-icon-xing-circle:before {
  content: '\e295';
}

.fl-icon-xing-regular:before {
  content: '\e095';
}

.fl-icon-xing.fl-icon-color-branded {
  color: #006567;
}

.fl-icon-pinterest-circle:before {
  content: '\e264';
}

.fl-icon-pinterest-regular:before {
  content: '\e064';
}

.fl-icon-pinterest.fl-icon-color-branded {
  color: #cb2127;
}

.fl-icon-tumblr-circle:before {
  content: '\e285';
}

.fl-icon-tumblr-regular:before {
  content: '\e085';
}

.fl-icon-tumblr.fl-icon-color-branded {
  color: #304e6c;
}

.fl-icon-vimeo-circle:before {
  content: '\e289';
}

.fl-icon-vimeo-regular:before {
  content: '\e089';
}

.fl-icon-vimeo.fl-icon-color-branded {
  color: #41b1db;
}

.fl-icon-youtube-circle:before {
  content: '\e299';
}

.fl-icon-youtube-regular:before {
  content: '\e099';
}

.fl-icon-youtube.fl-icon-color-branded {
  color: #d6000f;
}

.fl-icon-flickr-circle:before {
  content: '\e229';
}

.fl-icon-flickr-regular:before {
  content: '\e029';
}

.fl-icon-flickr.fl-icon-color-branded {
  color: #ec1982;
}

.fl-icon-instagram-circle:before {
  content: '\e300';
}

.fl-icon-instagram-regular:before {
  content: '\e100';
}

.fl-icon-instagram.fl-icon-color-branded {
  color: #517fa5;
}

.fl-icon-skype-circle:before {
  content: '\e274';
}

.fl-icon-skype-regular:before {
  content: '\e074';
}

.fl-icon-skype.fl-icon-color-branded {
  color: #009EE5;
}

.fl-icon-dribbble-circle:before {
  content: '\e221';
}

.fl-icon-dribbble-regular:before {
  content: '\e021';
}

.fl-icon-dribbble.fl-icon-color-branded {
  color: #eb4a88;
}

.fl-icon-500px-circle:before {
  content: '\e200';
}

.fl-icon-500px-regular:before {
  content: '\e000';
}

.fl-icon-500px.fl-icon-color-branded {
  color: #333333;
}

.fl-icon-blogger-circle:before {
  content: '\e212';
}

.fl-icon-blogger-regular:before {
  content: '\e012';
}

.fl-icon-blogger.fl-icon-color-branded {
  color: #fe7300;
}

.fl-icon-github-circle:before {
  content: '\e236';
}

.fl-icon-github-regular:before {
  content: '\e036';
}

.fl-icon-github.fl-icon-color-branded {
  color: #333333;
}

.fl-icon-rss-circle:before {
  content: '\e271';
}

.fl-icon-rss-regular:before {
  content: '\e071';
}

.fl-icon-rss.fl-icon-color-branded {
  color: #e09026;
}

.fl-icon-email-circle:before {
  content: '\e224';
}

.fl-icon-email-regular:before {
  content: '\e024';
}

.fl-icon-email.fl-icon-color-branded {
  color: #333333;
}

.fl-social-icons .fa-stack.icon-facebook i.fa-circle.branded,
.fl-social-icons .fa-facebook.branded,
.fl-social-icons .fa-facebook.regular {
  color: #3b5997;
}

.fl-social-icons .fa-stack.icon-twitter i.fa-circle.branded,
.fl-social-icons .fa-twitter.branded,
.fl-social-icons .fa-twitter.regular {
  color: #00aaef;
}

.fl-social-icons .fa-stack.icon-google i.fa-circle.branded,
.fl-social-icons .fa-google.branded,
.fl-social-icons .fa-google.regular {
  color: #d13e2b;
}

.fl-social-icons .fa-stack.icon-linkedin i.fa-circle.branded,
.fl-social-icons .fa-linkedin.branded,
.fl-social-icons .fa-linkedin.regular {
  color: #007ab8;
}

.fl-social-icons .fa-stack.icon-yelp i.fa-circle.branded,
.fl-social-icons .fa-yelp.branded,
.fl-social-icons .fa-yelp.regular {
  color: #c31200;
}

.fl-social-icons .fa-stack.icon-xing i.fa-circle.branded,
.fl-social-icons .fa-xing.branded,
.fl-social-icons .fa-xing.regular {
  color: #006567;
}

.fl-social-icons .fa-stack.icon-pinterest i.fa-circle.branded,
.fl-social-icons .fa-pinterest.branded,
.fl-social-icons .fa-pinterest.regular {
  color: #cb2127;
}

.fl-social-icons .fa-stack.icon-tumblr i.fa-circle.branded,
.fl-social-icons .fa-tumblr.branded,
.fl-social-icons .fa-tumblr.regular {
  color: #304e6c;
}

.fl-social-icons .fa-stack.icon-vimeo i.fa-circle.branded,
.fl-social-icons .fa-vimeo.branded,
.fl-social-icons .fa-vimeo.regular {
  color: #41b1db;
}

.fl-social-icons .fa-stack.icon-youtube i.fa-circle.branded,
.fl-social-icons .fa-youtube.branded,
.fl-social-icons .fa-youtube.regular {
  color: #d6000f;
}

.fl-social-icons .fa-stack.icon-flickr i.fa-circle.branded,
.fl-social-icons .fa-flickr.branded,
.fl-social-icons .fa-flickr.regular {
  color: #ec1982;
}

.fl-social-icons .fa-stack.icon-instagram i.fa-circle.branded,
.fl-social-icons .fa-instagram.branded,
.fl-social-icons .fa-instagram.regular {
  color: #517fa5;
}

.fl-social-icons .fa-stack.icon-dribbble i.fa-circle.branded,
.fl-social-icons .fa-dribbble.branded,
.fl-social-icons .fa-dribbble.regular {
  color: #eb4a88;
}

.fl-social-icons .fa-stack.icon-skype i.fa-circle.branded,
.fl-social-icons .fa-skype.branded,
.fl-social-icons .fa-skype.regular {
  color: #009EE5;
}

.fl-social-icons .fa-stack.icon-500px i.fa-circle.branded,
.fl-social-icons .fa-500px.branded,
.fl-social-icons .fa-500px.regular {
  color: #333333;
}

.fl-social-icons .fa-stack.icon-github i.fa-circle.branded,
.fl-social-icons .fa-github.branded,
.fl-social-icons .fa-github.regular {
  color: #333333;
}

.fl-social-icons .fa-stack.icon-rss i.fa-circle.branded,
.fl-social-icons .fa-rss.branded,
.fl-social-icons .fa-rss.regular {
  color: #e09026;
}

.fl-social-icons .fa-stack.icon-envelope i.fa-circle.branded,
.fl-social-icons .fa-envelope.branded,
.fl-social-icons .fa-envelope.regular {
  color: #333333;
}

.fl-social-icons .fa-stack i.branded {
  color: #fff;
}

.fl-social-icons .fa-stack {
  margin: 0 0 10px 8px;
}

.fl-social-icons .fallback {
  height: 28px;
  width: 28px;
  margin: 0 0 10px 5px !important;
  vertical-align: inherit;
  font-size: 30px;
}

.fl-social-icons .fallback:before {
  width: 28px !important;
  display: inline-block;
  height: 28px !important;
}

.fl-page-bar .fl-social-icons {
  padding-right: 2px;
}

.fl-page-bar .fl-social-icons .fl-icon-blogger {
  vertical-align: middle;
}

.fl-page-bar .fl-social-icons i.fa {
  font-size: 14px;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.fl-page-bar .fl-social-icons i.fa.fa-envelope {
  height: 26px;
}

.fl-page-footer .fl-social-icons .fallback {
  font-size: 26px;
  line-height: 24px;
}

.fl-page-header-content .fa-stack i.fa-inverse.mono {
  color: #ffffff;
}

.fa-stack i.fa-inverse.mono {
  color: #ffffff;
}

.fl-404 {
  padding: 30px 0 50px;
  text-align: center;
}

.fl-404 .fl-post-header {
  margin-bottom: 45px;
}

.fl-404 .fl-post-header h2 {
  font-size: 24px;
}

.fl-404 form {
  margin: 0 auto;
  max-width: 400px;
}

.fl-archive .fl-post {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 40px;
}

.fl-archive .fl-post:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.fl-archive .fl-archive-title {
  border-bottom: 1px solid #e6e6e6;
  font-size: 18px;
  margin-bottom: 40px;
  padding-bottom: 8px;
}

.fl-archive .fl-archive-title a:hover {
  text-decoration: none;
}

.fl-archive .fl-archive-nav-prev {
  float: left;
}

.fl-archive .fl-archive-nav-next {
  float: right;
}

.wp-pagenavi a,
.wp-pagenavi span {
  border: 1px solid #e6e6e6;
  border-right: none;
  margin: 0;
  padding: 6px 10px;
}

.wp-pagenavi a:hover {
  border-color: #e6e6e6;
}

.wp-pagenavi > *:last-child {
  border-right: 1px solid #e6e6e6;
}

.wp-pagenavi span.current,
.wp-pagenavi a:hover {
  border-color: #e6e6e6;
  color: #ffffff;
  background: #1d75bd;
  font-weight: normal;
}

.search .page .fl-post-header {
  display: block;
}

.fl-post {
  margin-bottom: 40px;
}

.fl-post:last-child {
  margin-bottom: 0;
}

.fl-post img[class*="wp-image-"],
.fl-post img.alignnone,
.fl-post img.alignleft,
.fl-post img.aligncenter,
.fl-post img.alignright {
  height: auto;
  max-width: 100%;
}

.fl-post .fl-post-image-beside {
  float: left;
  margin-right: 25px;
}

.fl-post .fl-author-box {
  margin-top: 40px;
  background: #f5f5f5;
  border: 1px solid #ddd;
  padding: 20px;
}

.fl-post .fl-author-box .fl-author-avatar {
  float: left;
  margin-right: 25px;
}

.fl-post .fl-author-box .fl-author-description h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.fl-post-thumb {
  margin-bottom: 25px;
}

.fl-post-thumb img {
  height: auto !important;
  max-width: 100%;
}

.fl-post-header {
  margin-bottom: 25px;
}

.fl-post-title {
  margin-top: 0;
}

.fl-post-meta .fl-sep {
  margin: 0 3px;
}

.fl-post-meta-bottom {
  border-top: 1px solid #e6e6e6;
  font-style: italic;
  margin-top: 20px;
  padding-top: 10px;
}

.fl-comments-popup-link a:hover {
  text-decoration: none;
}

.fl-comments-popup-link i {
  vertical-align: text-top;
}

.post-edit-link {
  color: #1d75bd;
  font-size: 14px;
  font-weight: normal;
}

.fl-post-nav {
  border-top: 1px solid #e6e6e6;
  margin-top: 10px;
  padding-top: 10px;
}

.fl-post-nav-prev {
  float: left;
}

.fl-post-nav-next {
  float: right;
}

.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #f7f7f7;
  border: 1px solid #e6e6e6;
  max-width: 100%;
  padding: 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 5px;
}

.fl-comments {
  margin-top: 60px;
}

.fl-comment-form .fl-comment-form-cancel {
  padding: 15px 0;
}

.fl-comments-list ol {
  margin: 0;
  padding: 0;
}

.fl-comments-list ol li {
  line-height: inherit;
  list-style: none;
}

.fl-comments-list .fl-comments-list-nav {
  border-top: 1px solid #e6e6e6;
  margin-top: 30px;
  padding: 10px 0 30px 0;
}

.fl-comments-list .fl-comments-list-prev {
  float: left;
}

.fl-comments-list .fl-comments-list-next {
  float: right;
}

.fl-comments-list .fl-comments-respond-title {
  margin-top: 0;
}

.fl-comments-list .comment-meta {
  margin: 20px 0 10px;
}

.fl-comments-list .comment-avatar img {
  height: auto;
  max-width: 40px;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.fl-comments-list .comment-author-link {
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
}

.fl-comments-list .comment-content {
  border: 1px solid #e6e6e6;
  padding: 15px;
}

.fl-comments-list .comment-reply-link {
  padding: 5px;
  text-align: right;
}

.fl-comments-list ul.children {
  margin: 0;
  padding: 0;
}

.fl-comments-list ul.children li {
  margin: 0 0 0 6%;
}

#wp-comment-cookies-consent {
  margin-right: 5px;
}

.fl-widget {
  margin-bottom: 40px;
}

.fl-module-widget .fl-widget {
  margin-bottom: 0;
}

.fl-widget .fl-widget-title {
  margin-bottom: 20px;
}

.fl-widget ul {
  list-style: none;
  padding: 0;
}

.fl-widget ul li {
  display: block;
  margin-bottom: 8px;
}

.fl-widget ul li:last-child {
  margin-bottom: 0;
}

.fl-widget ul.sub-menu li {
  margin: 0;
  padding: 5px;
}

.fl-widget ul.sub-menu li:before {
  content: "\2013";
  padding-right: 5px;
}

input[type=text].fl-search-input {
  color: rgba(66, 66, 68, 0.6);
  width: 100%;
}

input[type=text].fl-search-input:focus {
  color: #424244;
}

.widget_calendar table {
  width: 100%;
}

.widget_calendar caption,
.widget_calendar th,
.widget_calendar td {
  border-bottom: 1px solid #e6e6e6;
  padding: 3px 0;
  text-align: center;
}

.widget_calendar caption td,
.widget_calendar tfoot td {
  border: none;
}

.widget_calendar caption #prev,
.widget_calendar tfoot #prev {
  text-align: left;
}

.widget_calendar caption #next,
.widget_calendar tfoot #next {
  text-align: right;
}

a.fl-button,
a.fl-button:visited,
.fl-builder-content a.fl-button,
.fl-builder-content a.fl-button:visited {
  background: #1d75bd;
  color: #ffffff;
  border: 1px solid #155488;
}

a.fl-button *,
a.fl-button:visited *,
.fl-builder-content a.fl-button *,
.fl-builder-content a.fl-button:visited * {
  color: #ffffff;
}

a.fl-button:hover,
.fl-builder-content a.fl-button:hover {
  background: #428bca;
  color: #ffffff;
  border: 1px solid #2d6ca2;
}

a.fl-button:hover *,
.fl-builder-content a.fl-button:hover * {
  color: #ffffff;
}

button,
input[type=button],
input[type=submit] {
  background: #1d75bd;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;
  font-weight: normal;
  text-shadow: none;
  border: 1px solid #155488;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

button:hover,
input[type=button]:hover,
input[type=submit]:hover {
  background: #428bca;
  color: #ffffff;
  border: 1px solid #2d6ca2;
  text-decoration: none;
}

button:active,
button:focus,
input[type=button]:active,
input[type=button]:focus,
input[type=submit]:active,
input[type=submit]:focus {
  background: #428bca;
  color: #ffffff;
  border: 1px solid #2d6ca2;
  outline: none;
  position: relative;
  top: 1px;
}

button.btn-default,
button.btn-default:hover,
button.btn-default:focus,
button.btn-default:active,
button.btn-default.active,
input[type=button].btn-default,
input[type=button].btn-default:hover,
input[type=button].btn-default:focus,
input[type=button].btn-default:active,
input[type=button].btn-default.active,
input[type=submit].btn-default,
input[type=submit].btn-default:hover,
input[type=submit].btn-default:focus,
input[type=submit].btn-default:active,
input[type=submit].btn-default.active {
  color: #424244;
  background: #fcfcfc;
  border-color: #cccccc;
}

input[type=text],
input[type=password],
input[type=email],
input[type=tel],
input[type=date],
input[type=month],
input[type=week],
input[type=time],
input[type=number],
input[type=search],
input[type=url],
textarea {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #424244;
  vertical-align: middle;
  background-color: #fcfcfc;
  background-image: none;
  border: 1px solid #e6e6e6;
  -moz-transition: all ease-in-out .15s;
  -webkit-transition: all ease-in-out .15s;
  transition: all ease-in-out .15s;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

input[type=text]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=tel]::-webkit-input-placeholder,
input[type=date]::-webkit-input-placeholder,
input[type=month]::-webkit-input-placeholder,
input[type=week]::-webkit-input-placeholder,
input[type=time]::-webkit-input-placeholder,
input[type=number]::-webkit-input-placeholder,
input[type=search]::-webkit-input-placeholder,
input[type=url]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(66, 66, 68, 0.4);
}

input[type=text]:-moz-placeholder,
input[type=password]:-moz-placeholder,
input[type=email]:-moz-placeholder,
input[type=tel]:-moz-placeholder,
input[type=date]:-moz-placeholder,
input[type=month]:-moz-placeholder,
input[type=week]:-moz-placeholder,
input[type=time]:-moz-placeholder,
input[type=number]:-moz-placeholder,
input[type=search]:-moz-placeholder,
input[type=url]:-moz-placeholder,
textarea:-moz-placeholder {
  color: rgba(66, 66, 68, 0.4);
}

input[type=text]::-moz-placeholder,
input[type=password]::-moz-placeholder,
input[type=email]::-moz-placeholder,
input[type=tel]::-moz-placeholder,
input[type=date]::-moz-placeholder,
input[type=month]::-moz-placeholder,
input[type=week]::-moz-placeholder,
input[type=time]::-moz-placeholder,
input[type=number]::-moz-placeholder,
input[type=search]::-moz-placeholder,
input[type=url]::-moz-placeholder,
textarea::-moz-placeholder {
  color: rgba(66, 66, 68, 0.4);
}

input[type=text]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
input[type=tel]:-ms-input-placeholder,
input[type=date]:-ms-input-placeholder,
input[type=month]:-ms-input-placeholder,
input[type=week]:-ms-input-placeholder,
input[type=time]:-ms-input-placeholder,
input[type=number]:-ms-input-placeholder,
input[type=search]:-ms-input-placeholder,
input[type=url]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: rgba(66, 66, 68, 0.4);
}

input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=tel]:focus,
input[type=date]:focus,
input[type=month]:focus,
input[type=week]:focus,
input[type=time]:focus,
input[type=number]:focus,
input[type=search]:focus,
input[type=url]:focus,
textarea:focus {
  background-color: #ffffff;
  border-color: #cccccc;
  outline: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input[type=text],
input[type=password],
input[type=email],
input[type=tel],
input[type=date],
input[type=month],
input[type=week],
input[type=time],
input[type=number],
input[type=search],
input[type=url] {
  height: 34px;
}

.form-control {
  background-color: #fcfcfc;
  border-color: #e6e6e6;
  color: #424244;
  -moz-transition: all ease-in-out .15s;
  -webkit-transition: all ease-in-out .15s;
  transition: all ease-in-out .15s;
}

.form-control::-webkit-input-placeholder {
  color: rgba(66, 66, 68, 0.4);
}

.form-control:-moz-placeholder {
  color: rgba(66, 66, 68, 0.4);
}

.form-control::-moz-placeholder {
  color: rgba(66, 66, 68, 0.4);
}

.form-control:-ms-input-placeholder {
  color: rgba(66, 66, 68, 0.4);
}

.form-control:focus {
  background-color: #ffffff;
  border-color: #cccccc;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.mfp-wrap button.mfp-arrow,
.mfp-wrap button.mfp-arrow:active,
.mfp-wrap button.mfp-arrow:hover,
.mfp-wrap button.mfp-arrow:focus {
  background: transparent !important;
  border: none !important;
  outline: none;
  position: absolute;
  top: 50%;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.mfp-wrap .mfp-close,
.mfp-wrap .mfp-close:active,
.mfp-wrap .mfp-close:hover,
.mfp-wrap .mfp-close:focus {
  background: transparent !important;
  border: none !important;
  outline: none;
  position: absolute;
  top: 0;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

img.mfp-img {
  padding: 0;
}

.mfp-counter {
  display: none;
}

.fl-builder-edit .fl-page-header-fixed {
  display: none !important;
}

.fl-builder .container.fl-content-full {
  margin: 0;
  padding: 0;
  width: auto;
  max-width: none;
}

.fl-builder .container.fl-content-full > .row {
  margin: 0;
}

.fl-builder .container.fl-content-full .fl-content {
  margin: 0;
  padding: 0;
}

.fl-builder .container.fl-content-full .fl-post-header {
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 40px;
}

.fl-builder .container.fl-content-full .fl-comments {
  margin-left: 20px;
  margin-right: 20px;
}

@media (max-width: 767px) {
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile {
      position: relative;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile.fl-page-nav-right .fl-page-nav-collapse ul.navbar-nav {
      float: none !important;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile.fl-page-nav-right .fl-page-header-row .fl-page-header-logo {
      padding-bottom: 5px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile.fl-page-nav-left .fl-page-header-row .fl-page-header-logo {
      padding-bottom: 5px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile.fl-page-nav-left .fl-page-nav ul.navbar-nav > li:first-child a {
      padding-left: 17px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile.fl-page-nav-centered-inline-logo {
      text-align: left;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile.fl-page-nav-centered-inline-logo .fl-page-header-logo {
      display: block;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile.fl-page-header-vertical {
      top: 0;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse {
      border-top: 1px solid #e6e6e6;
      padding-bottom: 10px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse.collapse {
      display: none !important;
      visibility: hidden !important;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse.collapse.in {
      display: block !important;
      visibility: visible !important;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.navbar-nav {
      display: block;
      float: none;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.navbar-nav > li {
      border-color: #e6e6e6;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      display: block;
      float: none;
      position: relative;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.navbar-nav > li > a {
      color: #424244;
      text-align: left;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.navbar-nav > li > a:hover,
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.navbar-nav > li > a:focus {
      color: #1d75bd;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item > a {
      color: #1d75bd;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item ~ li.current-menu-item > a {
      color: #424244;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.sub-menu li {
      border-color: #e6e6e6;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.sub-menu li a {
      color: #424244;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.sub-menu li a:hover {
      color: #1d75bd;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse .fl-page-nav-search {
      display: none;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.sub-menu {
      background-color: transparent;
      border: none;
      float: none;
      height: 100%;
      list-style: none;
      overflow: hidden;
      padding: 0;
      position: absolute;
      top: 0;
      width: auto;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.sub-menu ul.sub-menu {
      padding-bottom: 0;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.sub-menu li {
      border-top: 1px solid #e6e6e6;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.sub-menu li a {
      clear: both;
      color: #424244;
      display: block;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
      padding: 15px 20px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.sub-menu li a:hover,
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.sub-menu li a:focus {
      color: #1d75bd;
      text-decoration: none;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.sub-menu li a:before {
      content: '\f105';
      font-family: FontAwesome;
      margin-right: 10px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav ul li.fl-mobile-sub-menu-open ul.sub-menu {
      position: static;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.navbar-nav .sub-menu {
      position: absolute;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.navbar-nav li.menu-item-has-children > a:after {
      content: '\f107';
      font-family: FontAwesome;
      float: right;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > a:after {
      content: '\f106';
      font-family: FontAwesome;
      float: right;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > ul.sub-menu {
      position: static;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.navbar-nav li a {
      padding: 15px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-wrap {
      border: 0 none;
      margin: 0;
      padding-bottom: 0;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-header-logo {
      text-align: left;
      margin-right: 55px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-col,
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .navbar {
      position: static;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav-container {
      position: unset;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav .navbar-toggle {
      background: transparent;
      display: block;
      font-size: 22px;
      line-height: 19px;
      padding: 6px 8px;
      position: absolute;
      right: 20px;
      top: -1px;
      width: auto;
      color: #424244;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav .navbar-toggle:hover,
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav .navbar-toggle:focus {
      background: transparent;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-mobile .fl-page-nav .navbar-toggle * {
      color: #424244;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile.fl-page-nav-right .fl-page-nav-collapse ul.navbar-nav {
      float: none !important;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile.fl-page-nav-right .fl-page-header-row .fl-page-header-logo {
      padding-bottom: 5px;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile.fl-page-nav-left .fl-page-header-row .fl-page-header-logo {
      padding-bottom: 5px;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile.fl-page-nav-left .fl-page-nav ul.navbar-nav > li:first-child a {
      padding-left: 17px;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile.fl-page-nav-centered-inline-logo {
      text-align: left;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile.fl-page-nav-centered-inline-logo .fl-page-header-logo {
      display: block;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile.fl-page-header-vertical {
      top: 0;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse {
      border-top: 1px solid #e6e6e6;
      padding-bottom: 10px;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse.collapse {
      display: none !important;
      visibility: hidden !important;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse.collapse.in {
      display: block !important;
      visibility: visible !important;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.navbar-nav {
      display: block;
      float: none;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.navbar-nav > li {
      border-color: #e6e6e6;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      display: block;
      float: none;
      position: relative;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.navbar-nav > li > a {
      color: #424244;
      text-align: left;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.navbar-nav > li > a:hover,
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.navbar-nav > li > a:focus {
      color: #1d75bd;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item > a {
      color: #1d75bd;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item ~ li.current-menu-item > a {
      color: #424244;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.sub-menu li {
      border-color: #e6e6e6;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.sub-menu li a {
      color: #424244;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse ul.sub-menu li a:hover {
      color: #1d75bd;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav-collapse .fl-page-nav-search {
      display: none;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.sub-menu {
      background-color: transparent;
      border: none;
      float: none;
      height: 100%;
      list-style: none;
      overflow: hidden;
      padding: 0;
      position: absolute;
      top: 0;
      width: auto;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.sub-menu ul.sub-menu {
      padding-bottom: 0;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.sub-menu li {
      border-top: 1px solid #e6e6e6;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.sub-menu li a {
      clear: both;
      color: #424244;
      display: block;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
      padding: 15px 20px;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.sub-menu li a:hover,
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.sub-menu li a:focus {
      color: #1d75bd;
      text-decoration: none;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.sub-menu li a:before {
      content: '\f105';
      font-family: FontAwesome;
      margin-right: 10px;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav ul li.fl-mobile-sub-menu-open ul.sub-menu {
      position: static;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.navbar-nav .sub-menu {
      position: absolute;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.navbar-nav li.menu-item-has-children > a:after {
      content: '\f107';
      font-family: FontAwesome;
      float: right;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > a:after {
      content: '\f106';
      font-family: FontAwesome;
      float: right;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > ul.sub-menu {
      position: static;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav ul.navbar-nav li a {
      padding: 15px;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav-wrap {
      border: 0 none;
      margin: 0;
      padding-bottom: 20px;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav .navbar-toggle {
      display: block;
      background: #e6e6e6;
      color: #424244;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav .navbar-toggle * {
      color: #424244;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav .navbar-toggle:hover,
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile .fl-page-nav .navbar-toggle:focus {
      background: #e6e6e6;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-mobile.fl-page-header-fixed .fl-page-nav-wrap {
      padding-top: 20px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-bottom .fl-page-header-content {
      border-top: 1px solid #e6e6e6;
      margin-top: 20px;
  }
  .fl-submenu-indicator .fl-page-nav ul.navbar-nav li.menu-item-has-children > a:after {
      content: '\f107';
      font-family: FontAwesome;
      float: right;
  }
  .fl-submenu-indicator .fl-page-nav ul.sub-menu ul.sub-menu {
      margin-left: 15px;
  }
  .fl-post .fl-post-image-beside {
      float: none;
      margin-right: 0;
  }
  .fl-post .fl-post-image-beside,
  .fl-post .fl-post-content-beside {
      padding: 0 15px;
  }
}

@media (min-width: 768px) {
  .container {
      padding-left: 40px;
      padding-right: 40px;
      width: auto;
  }
  .fl-page-bar .fl-page-bar-nav {
      margin: 0 -10px 0;
  }
  .fl-page-bar .text-left .fl-page-bar-nav {
      text-align: left;
  }
  .fl-page-bar .text-left .fl-social-icons {
      margin-right: 5px;
  }
  .fl-page-bar .text-right .fl-page-bar-nav {
      text-align: right;
  }
  .fl-page-bar .text-right .fl-social-icons {
      margin-left: 5px;
  }
  .fl-page-bar .fl-page-bar-nav,
  .fl-page-bar .fl-page-bar-text,
  .fl-page-bar .fl-social-icons {
      display: inline-block;
      text-align: inherit;
      vertical-align: middle;
  }
  .fl-page-nav {
      background: transparent;
  }
  .fl-page-nav .navbar-nav li > a {
      padding: 15px 15px;
      color: #424244;
  }
  .fl-page-nav .navbar-nav li > a:hover,
  .fl-page-nav .navbar-nav li > a:focus {
      color: #428bca;
  }
  .fl-page-nav .navbar-nav li.current-menu-ancestor > a,
  .fl-page-nav .navbar-nav li.current-menu-parent > a,
  .fl-page-nav .navbar-nav li.current-menu-item > a {
      color: #428bca;
  }
  .fl-page-nav .navbar-nav li.current-menu-item ~ li.current-menu-item > a {
      color: #424244;
  }
  .fl-page-nav-wrap {
      border-bottom: 1px solid #e6e6e6;
      border-top: 1px solid #e6e6e6;
      padding: 0;
      border-color: #e6e6e6;
      background-color: #ffffff;
      color: #428bca;
  }
  .fl-page-nav-wrap *,
  .fl-page-nav-wrap h1,
  .fl-page-nav-wrap h2,
  .fl-page-nav-wrap h3,
  .fl-page-nav-wrap h4,
  .fl-page-nav-wrap h5,
  .fl-page-nav-wrap h6 {
      color: #428bca;
  }
  .fl-page-nav-wrap a,
  .fl-page-nav-wrap a *,
  .fl-page-nav-wrap a.fa {
      color: #428bca;
  }
  .fl-page-nav-wrap a:hover,
  .fl-page-nav-wrap a:focus,
  .fl-page-nav-wrap a:hover *,
  .fl-page-nav-wrap a:focus *,
  .fl-page-nav-wrap a.fa:hover,
  .fl-page-nav-wrap a.fa:focus {
      color: #428bca;
  }
  .fl-page-nav-wrap .navbar-nav li > a {
      color: #428bca;
  }
  .fl-page-nav-wrap .navbar-nav li > a:hover,
  .fl-page-nav-wrap .navbar-nav li > a:focus {
      color: #428bca;
  }
  .fl-page-nav-wrap .navbar-nav li.current-menu-ancestor > a,
  .fl-page-nav-wrap .navbar-nav li.current-menu-parent > a,
  .fl-page-nav-wrap .navbar-nav li.current-menu-item > a {
      color: #428bca;
  }
  .fl-page-nav-wrap .navbar-nav li.current-menu-item ~ li.current-menu-item > a {
      color: #428bca;
  }
  .fl-page-nav-toggle-visible-always .fl-page-header-wrap .fl-page-header-container,
  .fl-page-nav-toggle-visible-medium-mobile .fl-page-header-wrap .fl-page-header-container {
      padding-left: 40px;
      padding-right: 40px;
  }
  .fl-page-nav-collapse ul.navbar-nav > li {
      border: none;
  }
  .fl-page-nav-collapse ul.navbar-nav > li:first-child {
      border: none;
  }
  .fl-submenu-indicator .fl-page-nav ul.navbar-nav > li.menu-item-has-children > a:after {
      content: '\f107';
      font-family: FontAwesome;
      float: right;
      margin-left: 5px;
  }
  .fl-submenu-indicator .fl-page-nav ul.sub-menu li.menu-item-has-children > a:after {
      content: '\f105';
      font-family: FontAwesome;
      float: right;
  }
  .fl-submenu-indicator .fl-page-nav li.mega-menu ul.sub-menu li.menu-item-has-children > a:after {
      content: '';
      margin-left: 0;
  }
  .fl-submenu-indicator .fl-page-nav li.mega-menu ul.sub-menu ul.sub-menu ul.sub-menu ul.sub-menu {
      margin-left: 15px;
  }
  .fl-submenu-indicator.fl-nav-vertical-left .fl-page-nav ul.navbar-nav > li.menu-item-has-children > a:after {
      content: '\f105';
      font-family: FontAwesome;
      float: right;
  }
  .fl-submenu-indicator.fl-nav-vertical-right .fl-page-nav ul.navbar-nav > li.menu-item-has-children > a:after {
      content: '\f104';
      font-family: FontAwesome;
      float: left;
  }
  .fl-submenu-indicator.fl-nav-vertical-right .fl-page-nav ul.sub-menu li.menu-item-has-children > a:after {
      content: '\f104';
      font-family: FontAwesome;
      float: left;
  }
  .fl-submenu-indicator.fl-nav-vertical-right .fl-page-nav li.mega-menu ul.sub-menu ul.sub-menu ul.sub-menu ul.sub-menu {
      margin-left: 0;
  }
  .fl-page-nav-bottom .fl-page-header-row {
      display: table;
      table-layout: fixed;
      width: 100%;
  }
  .fl-page-nav-bottom .fl-page-header-row .col-md-6 {
      display: table-cell;
      float: none;
      vertical-align: middle;
  }
  .fl-page-nav-bottom .fl-page-header-logo {
      text-align: left;
  }
  .fl-page-nav-bottom .fl-page-header-content {
      margin-right: -15px;
      text-align: right;
  }
  .fl-page-nav-bottom .fl-page-header-content .fl-page-header-text {
      margin: 0 0 10px;
  }
  .fl-page-nav-bottom .fl-page-header-content .fl-social-icons {
      margin: 0;
  }
  .fl-page-nav-bottom .fl-page-header-content .fl-social-icons .fl-icon {
      margin: 0 0 10px 10px;
  }
  .fl-page-nav-right {
      text-align: center;
  }
  .fl-page-nav-right .fl-page-header-container {
      padding-left: 15px;
      padding-right: 15px;
  }
  .fl-page-nav-right .fl-page-header-logo {
      padding-left: 40px;
      padding-right: 40px;
  }
  .fl-page-nav-right .fl-page-nav-wrap {
      margin-left: -15px;
      margin-right: -15px;
  }
  .fl-page-nav-right .fl-page-nav ul.navbar-nav {
      float: none !important;
  }
  .fl-page-nav-right .fl-page-nav ul.navbar-nav:last-child {
      margin-right: 0;
  }
  .fl-page-nav-right .fl-page-nav ul.navbar-nav > li {
      display: inline-block;
      float: none;
  }
  .fl-page-nav-right .fl-page-nav ul.sub-menu {
      text-align: left;
  }
  .fl-page-nav-left {
      text-align: center;
  }
  .fl-page-nav-left .fl-page-header-container {
      padding-left: 15px;
      padding-right: 15px;
  }
  .fl-page-nav-left .fl-page-header-logo {
      padding-left: 40px;
      padding-right: 40px;
  }
  .fl-page-nav-left .fl-page-nav-wrap {
      margin-left: -15px;
      margin-right: -15px;
  }
  .fl-page-nav-left .fl-page-nav ul.navbar-nav {
      float: none !important;
  }
  .fl-page-nav-left .fl-page-nav ul.navbar-nav:last-child {
      margin-right: 0;
  }
  .fl-page-nav-left .fl-page-nav ul.navbar-nav > li {
      display: inline-block;
      float: none;
  }
  .fl-page-nav-left .fl-page-nav ul.sub-menu {
      text-align: left;
  }
  .fl-page-nav-centered {
      text-align: center;
  }
  .fl-page-nav-centered .fl-page-nav ul.navbar-nav {
      float: none;
  }
  .fl-page-nav-centered .fl-page-nav ul.navbar-nav > li {
      display: inline-block;
      float: none;
  }
  .fl-page-nav-centered .fl-page-nav ul.sub-menu {
      text-align: left;
  }
  .fl-page-header-vertical {
      text-align: center;
  }
  .fl-page-header-vertical .fl-page-header-container {
      padding-left: 15px;
      padding-right: 15px;
  }
  .fl-page-header-vertical .fl-page-nav-wrap {
      margin-left: -15px;
      margin-right: -15px;
  }
  .fl-page-header-vertical .fl-page-nav ul.navbar-nav {
      float: none !important;
  }
  .fl-page-header-vertical .fl-page-nav ul.navbar-nav:last-child {
      margin-right: 0;
  }
  .fl-page-header-vertical .fl-page-nav ul.navbar-nav > li {
      display: inline-block;
      float: none;
  }
  .fl-page-header-vertical .fl-page-nav ul.sub-menu {
      text-align: left;
  }
  .fl-page-nav-centered-inline-logo {
      text-align: center;
  }
  .fl-page-nav-centered-inline-logo .fl-page-header-container {
      padding-left: 15px;
      padding-right: 15px;
  }
  .fl-page-nav-centered-inline-logo .fl-page-nav-wrap {
      margin-left: -15px;
      margin-right: -15px;
  }
  .fl-page-nav-centered-inline-logo .fl-page-nav ul.navbar-nav {
      float: none !important;
  }
  .fl-page-nav-centered-inline-logo .fl-page-nav ul.navbar-nav:last-child {
      margin-right: 0;
  }
  .fl-page-nav-centered-inline-logo .fl-page-nav ul.navbar-nav > li {
      display: inline-block;
      float: none;
  }
  .fl-page-nav-centered-inline-logo .fl-page-nav ul.sub-menu {
      text-align: left;
  }
  .fl-page-header-container,
  .fl-page-nav-container {
      position: relative;
  }
  .fl-page-nav-col,
  .fl-page-nav,
  .fl-page-fixed-nav-wrap {
      position: inherit;
  }
  ul.navbar-nav li.mega-menu {
      position: inherit;
  }
  ul.navbar-nav li.mega-menu.fl-sub-menu-open > ul.sub-menu {
      left: 0;
  }
  ul.navbar-nav li.mega-menu > ul.sub-menu {
      position: absolute !important;
      width: 100%;
      padding: 20px;
      display: table;
  }
  ul.navbar-nav li.mega-menu > ul.sub-menu ul.sub-menu {
      position: relative;
      width: auto;
      left: 0 !important;
      top: 0;
      opacity: 1 !important;
      padding: 0;
      border-top: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
  }
  ul.navbar-nav li.mega-menu > ul.sub-menu > li {
      position: relative;
      float: none;
      display: table-cell;
      height: auto;
      vertical-align: top;
      border-right: 1px solid #d9e8f4;
  }
  ul.navbar-nav li.mega-menu > ul.sub-menu > li:last-child {
      border-right: none;
  }
  ul.navbar-nav li.mega-menu > ul.sub-menu > li li li a::before {
      content: '\f105';
      font-family: FontAwesome;
      margin-right: 10px;
  }
  ul.navbar-nav li.mega-menu > ul.sub-menu > li > a {
      font-weight: bold;
  }
  ul.navbar-nav li.mega-menu.mega-menu-items-1 > ul > li {
      width: 100%;
  }
  ul.navbar-nav li.mega-menu.mega-menu-items-2 > ul > li {
      width: 50%;
  }
  ul.navbar-nav li.mega-menu.mega-menu-items-3 > ul > li {
      width: 33.33%;
  }
  ul.navbar-nav li.mega-menu.mega-menu-items-4 > ul > li {
      width: 25%;
  }
  ul.navbar-nav li.mega-menu.mega-menu-items-5 > ul > li {
      width: 20%;
  }
  ul.navbar-nav li.mega-menu.mega-menu-items-6 > ul > li {
      width: 16.66%;
  }
  .fl-content,
  .fl-sidebar {
      margin: 40px 0;
  }
  .fl-page-footer .fl-page-footer-text,
  .fl-page-footer .fl-social-icons {
      text-align: inherit;
  }
  .fl-page-footer .fl-page-footer-nav {
      margin: 0 -15px 10px;
  }
  .fl-page-footer .text-left .fl-social-icons .fl-icon {
      margin: 0 10px 20px 0;
  }
  .fl-page-footer .text-left .fl-page-footer-nav {
      text-align: left;
  }
  .fl-page-footer .text-right .fl-social-icons .fl-icon {
      margin: 0 0 20px 10px;
  }
  .fl-page-footer .text-right .fl-page-footer-nav {
      text-align: right;
  }
  .fl-full-width.fl-footer-effect .fl-page-footer-wrap {
      z-index: -1;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
  }
  .fl-404 {
      padding: 100px 0 150px;
  }
  .fl-404 .fl-post-header h2 {
      font-size: 30px;
  }
  .fl-builder .container.fl-content-full .fl-post-header,
  .fl-builder .container.fl-content-full .fl-comments {
      margin: 0 40px;
      width: auto;
  }
}

@media (max-width: 991px) {
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile {
      position: relative;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile.fl-page-nav-right .fl-page-nav-collapse ul.navbar-nav {
      float: none !important;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile.fl-page-nav-right .fl-page-header-row .fl-page-header-logo {
      padding-bottom: 5px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile.fl-page-nav-left .fl-page-header-row .fl-page-header-logo {
      padding-bottom: 5px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile.fl-page-nav-left .fl-page-nav ul.navbar-nav > li:first-child a {
      padding-left: 17px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile.fl-page-nav-centered-inline-logo {
      text-align: left;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile.fl-page-nav-centered-inline-logo .fl-page-header-logo {
      display: block;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile.fl-page-header-vertical {
      top: 0;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse {
      border-top: 1px solid #e6e6e6;
      padding-bottom: 10px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse.collapse {
      display: none !important;
      visibility: hidden !important;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse.collapse.in {
      display: block !important;
      visibility: visible !important;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.navbar-nav {
      display: block;
      float: none;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.navbar-nav > li {
      border-color: #e6e6e6;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      display: block;
      float: none;
      position: relative;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.navbar-nav > li > a {
      color: #424244;
      text-align: left;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.navbar-nav > li > a:hover,
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.navbar-nav > li > a:focus {
      color: #1d75bd;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item > a {
      color: #1d75bd;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item ~ li.current-menu-item > a {
      color: #424244;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.sub-menu li {
      border-color: #e6e6e6;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.sub-menu li a {
      color: #424244;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.sub-menu li a:hover {
      color: #1d75bd;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse .fl-page-nav-search {
      display: none;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.sub-menu {
      background-color: transparent;
      border: none;
      float: none;
      height: 100%;
      list-style: none;
      overflow: hidden;
      padding: 0;
      position: absolute;
      top: 0;
      width: auto;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.sub-menu ul.sub-menu {
      padding-bottom: 0;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.sub-menu li {
      border-top: 1px solid #e6e6e6;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.sub-menu li a {
      clear: both;
      color: #424244;
      display: block;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
      padding: 15px 20px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.sub-menu li a:hover,
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.sub-menu li a:focus {
      color: #1d75bd;
      text-decoration: none;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.sub-menu li a:before {
      content: '\f105';
      font-family: FontAwesome;
      margin-right: 10px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul li.fl-mobile-sub-menu-open ul.sub-menu {
      position: static;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.navbar-nav .sub-menu {
      position: absolute;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.navbar-nav li.menu-item-has-children > a:after {
      content: '\f107';
      font-family: FontAwesome;
      float: right;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > a:after {
      content: '\f106';
      font-family: FontAwesome;
      float: right;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > ul.sub-menu {
      position: static;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.navbar-nav li a {
      padding: 15px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-wrap {
      border: 0 none;
      margin: 0;
      padding-bottom: 0;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-header-logo {
      text-align: left;
      margin-right: 55px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-col,
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .navbar {
      position: static;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-container {
      position: unset;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav .navbar-toggle {
      background: transparent;
      display: block;
      font-size: 22px;
      line-height: 19px;
      padding: 6px 8px;
      position: absolute;
      right: 20px;
      top: -1px;
      width: auto;
      color: #424244;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav .navbar-toggle:hover,
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav .navbar-toggle:focus {
      background: transparent;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav .navbar-toggle * {
      color: #424244;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile.fl-page-nav-right .fl-page-nav-collapse ul.navbar-nav {
      float: none !important;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile.fl-page-nav-right .fl-page-header-row .fl-page-header-logo {
      padding-bottom: 5px;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile.fl-page-nav-left .fl-page-header-row .fl-page-header-logo {
      padding-bottom: 5px;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile.fl-page-nav-left .fl-page-nav ul.navbar-nav > li:first-child a {
      padding-left: 17px;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile.fl-page-nav-centered-inline-logo {
      text-align: left;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile.fl-page-nav-centered-inline-logo .fl-page-header-logo {
      display: block;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile.fl-page-header-vertical {
      top: 0;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse {
      border-top: 1px solid #e6e6e6;
      padding-bottom: 10px;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse.collapse {
      display: none !important;
      visibility: hidden !important;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse.collapse.in {
      display: block !important;
      visibility: visible !important;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.navbar-nav {
      display: block;
      float: none;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.navbar-nav > li {
      border-color: #e6e6e6;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      display: block;
      float: none;
      position: relative;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.navbar-nav > li > a {
      color: #424244;
      text-align: left;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.navbar-nav > li > a:hover,
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.navbar-nav > li > a:focus {
      color: #1d75bd;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item > a {
      color: #1d75bd;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.navbar-nav > li.current-menu-item ~ li.current-menu-item > a {
      color: #424244;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.sub-menu li {
      border-color: #e6e6e6;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.sub-menu li a {
      color: #424244;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse ul.sub-menu li a:hover {
      color: #1d75bd;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-collapse .fl-page-nav-search {
      display: none;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.sub-menu {
      background-color: transparent;
      border: none;
      float: none;
      height: 100%;
      list-style: none;
      overflow: hidden;
      padding: 0;
      position: absolute;
      top: 0;
      width: auto;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.sub-menu ul.sub-menu {
      padding-bottom: 0;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.sub-menu li {
      border-top: 1px solid #e6e6e6;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.sub-menu li a {
      clear: both;
      color: #424244;
      display: block;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
      padding: 15px 20px;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.sub-menu li a:hover,
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.sub-menu li a:focus {
      color: #1d75bd;
      text-decoration: none;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.sub-menu li a:before {
      content: '\f105';
      font-family: FontAwesome;
      margin-right: 10px;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul li.fl-mobile-sub-menu-open ul.sub-menu {
      position: static;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.navbar-nav .sub-menu {
      position: absolute;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.navbar-nav li.menu-item-has-children > a:after {
      content: '\f107';
      font-family: FontAwesome;
      float: right;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > a:after {
      content: '\f106';
      font-family: FontAwesome;
      float: right;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.navbar-nav li.fl-mobile-sub-menu-open > ul.sub-menu {
      position: static;
  }
  .fl-submenu-toggle .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav ul.navbar-nav li a {
      padding: 15px;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav-wrap {
      border: 0 none;
      margin: 0;
      padding-bottom: 20px;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav .navbar-toggle {
      display: block;
      background: #e6e6e6;
      color: #424244;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav .navbar-toggle * {
      color: #424244;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav .navbar-toggle:hover,
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile .fl-page-nav .navbar-toggle:focus {
      background: #e6e6e6;
  }
  .fl-page-nav-toggle-button.fl-page-nav-toggle-visible-medium-mobile.fl-page-header-fixed .fl-page-nav-wrap {
      padding-top: 20px;
  }
  .fl-page-nav-toggle-icon.fl-page-nav-bottom .fl-page-header-content {
      margin-right: 0;
  }
}

@media (min-width: 992px) {
  body.fl-fixed-width:not(.fl-nav-vertical):not(.fl-fixed-header):not(.fl-shrink) {
      padding: 0;
  }
  .container {
      padding-left: 0;
      padding-right: 0;
      width: 900px;
  }
  .fl-full-width .container {
      padding-left: 40px;
      padding-right: 40px;
      width: auto;
  }
  .fl-page {
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      width: 980px;
  }
  .fl-full-width .fl-page {
      width: auto;
  }
  .fl-page-nav-search {
      display: block;
  }
  .fl-page-nav-right {
      text-align: right;
  }
  .fl-page-nav-right .fl-page-header-wrap {
      border-bottom: 1px solid rgba(66, 66, 68, 0.13);
  }
  .fl-page-nav-right .fl-page-header-container {
      padding-bottom: 5px;
  }
  .fl-page-nav-right .fl-page-header-row {
      display: table;
      table-layout: fixed;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
  }
  .fl-page-nav-right .fl-page-header-row > div {
      display: table-cell;
      float: none;
      vertical-align: middle;
  }
  .fl-page-nav-right .fl-page-header-row .fl-page-header-logo-col {
      padding-left: 0;
  }
  .fl-page-nav-right .fl-page-header-row .fl-page-logo-wrap {
      padding-left: 0;
  }
  .fl-page-nav-right .fl-page-header-row .fl-page-nav-col {
      padding-right: 0;
  }
  .fl-page-nav-right.fl-page-nav-toggle-visible-always .fl-page-header-row {
      display: block;
      width: auto;
  }
  .fl-page-nav-right.fl-page-nav-toggle-visible-always .fl-page-header-row > div {
      display: block;
      width: 100%;
  }
  .fl-page-nav-right .fl-page-header-logo {
      padding: 0;
      text-align: left;
  }
  .fl-page-nav-right .fl-page-nav-wrap {
      border-color: #e6e6e6;
      background-color: #ffffff;
      color: #424244;
      background: none;
      border: none;
      margin-left: 0;
      margin-right: 0;
  }
  .fl-page-nav-right .fl-page-nav-wrap *,
  .fl-page-nav-right .fl-page-nav-wrap h1,
  .fl-page-nav-right .fl-page-nav-wrap h2,
  .fl-page-nav-right .fl-page-nav-wrap h3,
  .fl-page-nav-right .fl-page-nav-wrap h4,
  .fl-page-nav-right .fl-page-nav-wrap h5,
  .fl-page-nav-right .fl-page-nav-wrap h6 {
      color: #424244;
  }
  .fl-page-nav-right .fl-page-nav-wrap a,
  .fl-page-nav-right .fl-page-nav-wrap a *,
  .fl-page-nav-right .fl-page-nav-wrap a.fa {
      color: #424244;
  }
  .fl-page-nav-right .fl-page-nav-wrap a:hover,
  .fl-page-nav-right .fl-page-nav-wrap a:focus,
  .fl-page-nav-right .fl-page-nav-wrap a:hover *,
  .fl-page-nav-right .fl-page-nav-wrap a:focus *,
  .fl-page-nav-right .fl-page-nav-wrap a.fa:hover,
  .fl-page-nav-right .fl-page-nav-wrap a.fa:focus {
      color: #1d75bd;
  }
  .fl-page-nav-right .fl-page-nav-wrap .navbar-nav li > a {
      color: #424244;
  }
  .fl-page-nav-right .fl-page-nav-wrap .navbar-nav li > a:hover,
  .fl-page-nav-right .fl-page-nav-wrap .navbar-nav li > a:focus {
      color: #1d75bd;
  }
  .fl-page-nav-right .fl-page-nav-wrap .navbar-nav li.current-menu-ancestor > a,
  .fl-page-nav-right .fl-page-nav-wrap .navbar-nav li.current-menu-parent > a,
  .fl-page-nav-right .fl-page-nav-wrap .navbar-nav li.current-menu-item > a {
      color: #1d75bd;
  }
  .fl-page-nav-right .fl-page-nav-wrap .navbar-nav li.current-menu-item ~ li.current-menu-item > a {
      color: #424244;
  }
  .fl-page-nav-right .fl-page-nav {
      border: none;
      margin: 0;
  }
  .fl-page-nav-right .fl-page-nav ul.navbar-nav {
      float: right !important;
  }
  .fl-page-nav-right .fl-page-nav ul.navbar-nav > li {
      display: block;
      float: left;
  }
  .fl-fixed-width .fl-page-nav-right .fl-page-header-container {
      padding-left: 0;
      padding-right: 0;
  }
  .fl-page-header-fixed .fl-page-fixed-nav-wrap {
      padding-right: 0;
  }
  .fl-page-nav-vertical-left {
      left: 0;
  }
  .fl-page-nav-vertical-right {
      right: 0;
  }
  .fl-page-header-vertical:not(.fl-page-nav-toggle-visible-always) {
      position: fixed;
      z-index: 9;
      top: 0;
      width: 230px;
      height: 100%;
      text-align: left;
      -moz-box-shadow: 0 0 4px #cecece;
      -webkit-box-shadow: 0 0 4px #cecece;
      box-shadow: 0 0 4px #cecece;
  }
  .fl-page-header-vertical:not(.fl-page-nav-toggle-visible-always) .fl-page-nav-wrap {
      border: none;
      background: none;
      margin-left: auto;
      margin-right: auto;
  }
  .fl-page-header-vertical:not(.fl-page-nav-toggle-visible-always) .fl-page-header-logo {
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 0;
  }
  .fl-page-header-vertical:not(.fl-page-nav-toggle-visible-always) .fl-page-nav-collapse ul.navbar-nav {
      z-index: 9;
      padding-top: 30px;
  }
  .fl-page-header-vertical:not(.fl-page-nav-toggle-visible-always) .fl-page-nav-collapse ul.navbar-nav > li {
      width: 100%;
  }
  .fl-page-header-vertical:not(.fl-page-nav-toggle-visible-always) .fl-page-nav-collapse ul.navbar-nav > li > a {
      padding: 0 5px 15px 5px;
  }
  .fl-page-header-vertical:not(.fl-page-nav-toggle-visible-always) .fl-page-nav-collapse ul.navbar-nav > li > ul.sub-menu {
      top: -15px;
  }
  .fl-page-header-vertical:not(.fl-page-nav-toggle-visible-always) .fl-page-nav-collapse ul.navbar-nav ul.sub-menu {
      border-top: 0;
      width: auto;
      min-width: 230px;
  }
  .fl-page-header-vertical:not(.fl-page-nav-toggle-visible-always) .fl-page-nav-collapse ul.navbar-nav ul.sub-menu a {
      padding: 6px 15px;
      white-space: nowrap;
  }
  .fl-page-header-vertical:not(.fl-page-nav-toggle-visible-always) .fl-page-nav-search {
      position: relative;
      width: 100%;
      float: left;
  }
  .fl-page-header-vertical:not(.fl-page-nav-toggle-visible-always) .fl-page-nav-search a.fa-search {
      position: relative;
      padding: 0 5px;
      font-size: 14px;
  }
  .fl-page-header-vertical:not(.fl-page-nav-toggle-visible-always) .item-left ul.navbar-nav > li > a {
      float: left;
  }
  .fl-page-header-vertical:not(.fl-page-nav-toggle-visible-always) .item-right ul.navbar-nav > li > a,
  .fl-page-header-vertical:not(.fl-page-nav-toggle-visible-always) .item-right .fl-page-nav-search a {
      float: right;
  }
  .fl-page-header-vertical:not(.fl-page-nav-toggle-visible-always) .item-center ul.navbar-nav > li > a {
      text-align: center;
  }
  .fl-page-header-vertical:not(.fl-page-nav-toggle-visible-always) .item-center .fl-page-nav-search a {
      left: 50%;
      margin-left: -7px;
      padding: 0;
  }
  .fl-fixed-width .fl-page-nav-vertical-left {
      left: auto;
  }
  .fl-fixed-width .fl-page-nav-vertical-right {
      right: 0;
  }
  .fl-nav-vertical .fl-page-header-vertical .fl-page-header-container {
      width: auto;
      padding-left: 0;
      padding-right: 0;
      padding-top: 50px;
  }
  .fl-nav-vertical-left .fl-page-bar,
  .fl-nav-vertical-left .fl-page-content,
  .fl-nav-vertical-left .fl-page-footer-wrap,
  .fl-nav-vertical-left footer.fl-builder-content {
      margin-left: 230px;
  }
  .fl-nav-vertical-right .fl-page-bar,
  .fl-nav-vertical-right .fl-page-content,
  .fl-nav-vertical-right .fl-page-footer-wrap,
  .fl-nav-vertical-right footer.fl-builder-content {
      margin-right: 230px;
  }
  .page-template-tpl-no-header-footer.fl-nav-vertical .fl-page-content {
      margin-left: 0;
      margin-right: 0;
  }
  ul.navbar-vertical-left li.fl-sub-menu-open > ul.sub-menu,
  ul.navbar-vertical-left li.mega-menu.fl-sub-menu-open > ul.sub-menu {
      left: 230px;
  }
  ul.navbar-vertical-left li.fl-sub-menu-open > ul.sub-menu ul.sub-menu {
      top: -10px;
  }
  ul.navbar-vertical-left li.mega-menu.fl-sub-menu-open > ul.sub-menu ul.sub-menu {
      top: 0;
  }
  ul.navbar-vertical-right li.fl-sub-menu-open > ul.sub-menu,
  ul.navbar-vertical-right li.mega-menu.fl-sub-menu-open > ul.sub-menu {
      left: auto !important;
      right: 230px;
      text-align: right;
  }
  ul.navbar-vertical-right li.fl-sub-menu-open > ul.sub-menu ul.sub-menu {
      top: -10px;
  }
  ul.navbar-vertical-right li.mega-menu.fl-sub-menu-open > ul.sub-menu ul.sub-menu {
      top: 0;
      text-align: right;
  }
  .fl-page-nav ul.navbar-vertical-right li.fl-sub-menu-open > ul.sub-menu li.fl-sub-menu-open > ul.sub-menu {
      left: auto;
      right: 100%;
  }
  ul.navbar-vertical-left ul.sub-menu {
      border-left: 3px solid #a1c5e5;
  }
  ul.navbar-vertical-right ul.sub-menu {
      border-right: 3px solid #a1c5e5;
  }
  .fl-page-nav-vertical-left .fl-page-nav-search form {
      width: auto;
      top: -15px;
      left: 230px;
      min-width: 230px;
      padding: 12px;
      border-top: none;
      border-left: 3px solid #a1c5e5;
  }
  .fl-page-nav-vertical-right .fl-page-nav-search form {
      width: auto;
      top: -15px;
      right: 230px;
      min-width: 230px;
      padding: 12px;
      border-top: none;
      border-right: 3px solid #a1c5e5;
  }
  .fl-fixed-width.fl-nav-vertical .fl-page {
      width: 95%;
      max-width: 1400px;
  }
  .fl-fixed-width.fl-nav-vertical .fl-page-bar .container,
  .fl-fixed-width.fl-nav-vertical footer .container,
  .fl-fixed-width.fl-nav-vertical .fl-page-content .container {
      margin: auto;
      padding-left: 40px;
      padding-right: 40px;
      width: auto;
  }
  .fl-fixed-width.fl-nav-vertical.fl-builder .fl-content-full.container {
      padding-left: 0;
      padding-right: 0;
  }
  .fl-page-nav-centered-inline-logo {
      text-align: center;
  }
  .fl-page-nav-centered-inline-logo .fl-page-header-container {
      padding-bottom: 5px;
  }
  .fl-page-nav-centered-inline-logo .fl-page-nav ul.navbar-nav {
      float: none;
      display: inline-block;
  }
  .fl-page-nav-centered-inline-logo .fl-page-nav ul.navbar-nav > li {
      display: inline-block;
      float: none;
  }
  .fl-page-nav-centered-inline-logo .fl-page-nav ul.sub-menu {
      text-align: left;
  }
  .fl-page-nav-centered-inline-logo .fl-page-nav-wrap {
      border: none;
      background: none;
  }
  .fl-page-nav-centered-inline-logo .fl-page-nav-search {
      position: relative;
      float: none;
      display: inline-block;
  }
  .fl-page-nav-centered-inline-logo .fl-page-nav-search a.fa-search {
      padding: 13px 0 13px 10px;
      position: static;
  }
  .fl-page-nav-centered-inline-logo .fl-page-header-logo {
      display: none;
  }
  .fl-page-nav-centered-inline-logo .fl-logo-centered-inline .fl-page-header-logo {
      display: block;
      float: none;
      padding: 0 15px;
  }
  .fl-page-nav-left {
      text-align: right;
  }
  .fl-page-nav-left .fl-page-header-wrap {
      border-bottom: 1px solid rgba(66, 66, 68, 0.13);
  }
  .fl-page-nav-left .fl-page-header-container {
      padding-bottom: 5px;
  }
  .fl-page-nav-left .fl-page-header-row {
      display: table;
      table-layout: fixed;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
  }
  .fl-page-nav-left .fl-page-header-row > div {
      display: table-cell;
      float: none;
      vertical-align: middle;
  }
  .fl-page-nav-left .fl-page-header-row .fl-page-header-logo-col {
      padding-right: 0;
  }
  .fl-page-nav-left .fl-page-header-row .fl-page-logo-wrap {
      padding-right: 0;
  }
  .fl-page-nav-left .fl-page-header-row .fl-page-nav-col {
      padding-left: 0;
  }
  .fl-page-nav-left.fl-page-nav-toggle-visible-always .fl-page-header-row {
      display: block;
      width: auto;
  }
  .fl-page-nav-left.fl-page-nav-toggle-visible-always .fl-page-header-row > div {
      display: block;
      width: 100%;
  }
  .fl-page-nav-left.fl-page-nav-toggle-visible-always .fl-page-header-row {
      display: block;
      width: auto;
  }
  .fl-page-nav-left.fl-page-nav-toggle-visible-always .fl-page-header-row > div {
      display: block;
      width: 100%;
  }
  .fl-page-nav-left .fl-page-header-logo {
      padding: 0;
      text-align: right;
  }
  .fl-page-nav-left .fl-page-nav-wrap {
      border-color: #e6e6e6;
      background-color: #ffffff;
      color: #424244;
      background: none;
      border: none;
      margin-left: 0;
      margin-right: 0;
  }
  .fl-page-nav-left .fl-page-nav-wrap *,
  .fl-page-nav-left .fl-page-nav-wrap h1,
  .fl-page-nav-left .fl-page-nav-wrap h2,
  .fl-page-nav-left .fl-page-nav-wrap h3,
  .fl-page-nav-left .fl-page-nav-wrap h4,
  .fl-page-nav-left .fl-page-nav-wrap h5,
  .fl-page-nav-left .fl-page-nav-wrap h6 {
      color: #424244;
  }
  .fl-page-nav-left .fl-page-nav-wrap a,
  .fl-page-nav-left .fl-page-nav-wrap a *,
  .fl-page-nav-left .fl-page-nav-wrap a.fa {
      color: #424244;
  }
  .fl-page-nav-left .fl-page-nav-wrap a:hover,
  .fl-page-nav-left .fl-page-nav-wrap a:focus,
  .fl-page-nav-left .fl-page-nav-wrap a:hover *,
  .fl-page-nav-left .fl-page-nav-wrap a:focus *,
  .fl-page-nav-left .fl-page-nav-wrap a.fa:hover,
  .fl-page-nav-left .fl-page-nav-wrap a.fa:focus {
      color: #1d75bd;
  }
  .fl-page-nav-left .fl-page-nav-wrap .navbar-nav li > a {
      color: #424244;
  }
  .fl-page-nav-left .fl-page-nav-wrap .navbar-nav li > a:hover,
  .fl-page-nav-left .fl-page-nav-wrap .navbar-nav li > a:focus {
      color: #1d75bd;
  }
  .fl-page-nav-left .fl-page-nav-wrap .navbar-nav li.current-menu-ancestor > a,
  .fl-page-nav-left .fl-page-nav-wrap .navbar-nav li.current-menu-parent > a,
  .fl-page-nav-left .fl-page-nav-wrap .navbar-nav li.current-menu-item > a {
      color: #1d75bd;
  }
  .fl-page-nav-left .fl-page-nav-wrap .navbar-nav li.current-menu-item ~ li.current-menu-item > a {
      color: #424244;
  }
  .fl-page-nav-left .fl-page-nav {
      border: none;
      margin: 0;
  }
  .fl-page-nav-left .fl-page-nav ul.navbar-nav {
      float: left;
  }
  .fl-page-nav-left .fl-page-nav ul.navbar-nav > li:first-child a {
      padding-left: 0;
  }
  .fl-page-nav-left .fl-page-nav ul.navbar-nav > li {
      display: block;
      float: left;
  }
  .fl-page-nav-left .fl-page-nav-search form {
      right: auto;
      left: 0;
  }
  .fl-fixed-width .fl-page-nav-left .fl-page-header-container {
      padding-left: 0;
      padding-right: 0;
  }
  .fl-nav-left .fl-page-header-fixed:not(.fl-page-nav-toggle-visible-always) ul.navbar-nav {
      float: left !important;
  }
  .fl-nav-left .fl-page-header-fixed:not(.fl-page-nav-toggle-visible-always) ul.navbar-nav > li:first-child a {
      padding-left: 0;
  }
  .fl-nav-left .fl-page-header-fixed:not(.fl-page-nav-toggle-visible-always) .fl-page-header-logo {
      padding-right: 10px;
      text-align: right;
  }
  .fl-nav-left .fl-page-header-fixed:not(.fl-page-nav-toggle-visible-always) .fl-page-fixed-nav-wrap {
      padding-left: 0;
  }
  .fl-nav-left .fl-page-header-fixed:not(.fl-page-nav-toggle-visible-always) .fl-page-header-logo {
      padding-right: 0;
  }
  .fl-nav-left .fl-page-header-fixed:not(.fl-page-nav-toggle-visible-always) .fl-page-logo-wrap {
      padding-left: 15px;
      padding-right: 0;
  }
  .fl-shrink-header-enabled.admin-bar .fl-page-bar {
      top: 32px;
  }
  .fl-shrink-header-enabled .fl-page-bar {
      position: fixed;
      min-width: 100%;
      top: 0;
      left: 0;
      right: 0;
      z-index: 101;
  }
  .fl-shrink-header-enabled.admin-bar .fl-page-header {
      top: 32px;
  }
  .fl-shrink-header-enabled .fl-page-header {
      position: fixed;
      min-width: 100%;
      top: 0;
      z-index: 100;
  }
  .fl-shrink-header-enabled.fl-fixed-width .fl-page-bar,
  .fl-shrink-header-enabled.fl-fixed-width .fl-page-header {
      width: 980px;
      min-width: 0;
      margin: 0 auto;
  }
  .fl-shrink-header .fl-logo-img {
      max-height: 46px !important;
      width: auto !important;
  }
  .fl-shrink-header .fl-page-header-container.container {
      padding-top: 8px;
      padding-bottom: 8px;
  }
  .fl-shrink-header-transition .fl-logo-img,
  .fl-shrink-header-transition .fl-page-header-container.container {
      -webkit-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
  }
  .fl-builder-edit .fl-shrink.admin-bar .fl-page-bar {
      top: 43px;
  }
  .fl-fixed-header.admin-bar .fl-page-bar {
      top: 32px;
  }
  .fl-fixed-header .fl-page-bar {
      position: fixed;
      min-width: 100%;
      top: 0;
      left: 0;
      right: 0;
      z-index: 101;
  }
  .fl-fixed-header.admin-bar .fl-page-header {
      top: 32px;
  }
  .fl-fixed-header .fl-page-header {
      position: fixed;
      min-width: 100%;
      top: 0;
      z-index: 100;
  }
  .fl-fixed-header.fl-fixed-width .fl-page-bar,
  .fl-fixed-header.fl-fixed-width .fl-page-header {
      width: 980px;
      min-width: 0;
      margin: 0 auto;
  }
  .fl-builder-edit .fl-fixed-header.admin-bar .fl-page-bar {
      top: 43px;
  }
  .fl-scroll-header .fl-page-header-primary,
  .fl-scroll-header .fl-page-bar {
      -webkit-transform: translateY(-200%);
      -ms-transform: translateY(-200%);
      transform: translateY(-200%);
      -webkit-transition: -webkit-transform .4s ease-in-out;
      -moz-transition: transform .4s ease-in-out;
      -o-transition: transform .4s ease-in-out;
      transition: transform .4s ease-in-out;
  }
  .fl-scroll-header .fl-page-header-primary.fl-show,
  .fl-scroll-header .fl-page-bar.fl-show {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
  }
  .fl-page-nav-vertical-left ul.navbar-nav li.mega-menu,
  .fl-page-nav-vertical-right ul.navbar-nav li.mega-menu {
      position: relative;
  }
  .fl-page-nav-vertical-left ul.navbar-nav li.mega-menu > ul.sub-menu,
  .fl-page-nav-vertical-right ul.navbar-nav li.mega-menu > ul.sub-menu {
      width: 740px;
  }
  .fl-page-nav-vertical-left ul.navbar-nav li.mega-menu > ul.sub-menu ul,
  .fl-page-nav-vertical-right ul.navbar-nav li.mega-menu > ul.sub-menu ul {
      min-width: 0;
  }
  .fl-page-nav-vertical-left ul.navbar-nav li.mega-menu ul ul {
      border-left: none;
  }
  .fl-page-nav-vertical-right ul.navbar-nav li.mega-menu ul ul {
      border-right: none;
  }
  .fl-sidebar {
      display: block;
  }
  .fl-content {
      margin: 40px 0;
  }
  .fl-content-left {
      border-right: 1px solid #e6e6e6;
      padding-right: 40px;
  }
  .fl-content-right {
      border-left: 1px solid #e6e6e6;
      padding-left: 40px;
  }
  .fl-sidebar-right {
      padding-left: 40px;
  }
  .fl-sidebar-left {
      padding-right: 40px;
  }
  .fl-page-footer-widgets {
      display: block;
  }
  .fl-page-footer-container {
      padding: 20px 0 0;
  }
  .fl-nav-vertical-right.fl-full-width.fl-footer-effect .fl-page-footer-wrap {
      padding-right: 230px;
  }
  .fl-nav-vertical-left.fl-full-width.fl-footer-effect .fl-page-footer-wrap {
      padding-left: 230px;
      margin-left: 0;
  }
  .fl-builder.fl-full-width .container.fl-content-full .fl-post-header,
  .fl-builder.fl-full-width .container.fl-content-full .fl-comments {
      margin: 0 40px;
      width: auto;
  }
}

@media (min-width: 1100px) {
  .fl-fixed-width .fl-page {
      width: 1100px;
      max-width: 90%;
  }
  .container {
      width: 1020px;
      max-width: 90%;
  }
  .fl-full-width .container {
      padding-left: 0;
      padding-right: 0;
      width: 1020px;
  }
  .fl-page-nav-toggle-visible-always .fl-page-header-wrap .fl-page-header-container,
  .fl-page-nav-toggle-visible-medium-mobile .fl-page-header-wrap .fl-page-header-container {
      padding-left: 0;
      padding-right: 0;
  }
  .fl-page-nav-right.fl-page-nav-toggle-visible-always .fl-page-header-container,
  .fl-page-nav-right.fl-page-nav-toggle-visible-medium-mobile .fl-page-header-container,
  .fl-page-nav-right .fl-page-header-container {
      padding-left: 0;
      padding-right: 0;
  }
  .fl-nav-vertical.fl-full-width.page.fl-builder .fl-content-full.container {
      max-width: none;
  }
  .fl-nav-vertical.fl-full-width .container {
      width: auto;
      max-width: 1020px;
  }
  .fl-nav-vertical.fl-full-width .fl-page-bar-container,
  .fl-nav-vertical.fl-full-width .fl-page-footer-wrap .container,
  .fl-nav-vertical.fl-full-width .fl-page-content .container {
      margin: auto;
      padding-left: 40px;
      padding-right: 40px;
  }
  .fl-nav-vertical.fl-full-width.fl-builder .fl-page-content .fl-content-full.container {
      padding-left: 0;
      padding-right: 0;
  }
  .fl-fixed-width.fl-nav-vertical .fl-page {
      width: 92%;
  }
  .fl-page-nav-left.fl-page-nav-toggle-visible-always .fl-page-header-container,
  .fl-page-nav-left.fl-page-nav-toggle-visible-medium-mobile .fl-page-header-container,
  .fl-page-nav-left .fl-page-header-container {
      padding-left: 0;
      padding-right: 0;
  }
  .fl-page-nav-centered-inline-logo.fl-page-nav-toggle-visible-always .fl-page-header-container,
  .fl-page-nav-centered-inline-logo.fl-page-nav-toggle-visible-medium-mobile .fl-page-header-container,
  .fl-page-nav-centered-inline-logo .fl-page-header-container {
      padding-left: 0;
      padding-right: 0;
  }
  .fl-shrink .fl-page-bar,
  .fl-shrink .fl-page-header {
      min-width: 100%;
      max-width: 90%;
  }
  .fl-shrink.fl-fixed-width .fl-page-bar,
  .fl-shrink.fl-fixed-width .fl-page-header {
      width: 1100px;
  }
  .fl-fixed-header .fl-page-bar,
  .fl-fixed-header .fl-page-header {
      max-width: 90%;
  }
  .fl-fixed-header.fl-fixed-width .fl-page-bar,
  .fl-fixed-header.fl-fixed-width .fl-page-header {
      width: 1100px;
  }
  .fl-page-nav-vertical-left ul.navbar-nav li.mega-menu > ul.sub-menu,
  .fl-page-nav-vertical-right ul.navbar-nav li.mega-menu > ul.sub-menu {
      width: 820px;
  }
  .fl-builder.fl-full-width .container.fl-content-full .fl-post-header,
  .fl-builder.fl-full-width .container.fl-content-full .fl-comments {
      margin: 0 auto;
      width: 1020px;
  }
}

@media (min-width: 1200px) {
  .fl-page-nav-vertical-left ul.navbar-nav li.mega-menu > ul.sub-menu,
  .fl-page-nav-vertical-right ul.navbar-nav li.mega-menu > ul.sub-menu {
      width: 900px;
  }
}

@media (min-width: 1400px) {
  .fl-page-nav-vertical-left ul.navbar-nav li.mega-menu > ul.sub-menu,
  .fl-page-nav-vertical-right ul.navbar-nav li.mega-menu > ul.sub-menu {
      width: 1000px;
  }
}

.fl-builder-content *,
.fl-builder-content *:before,
.fl-builder-content *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fl-row:before,
.fl-row:after,
.fl-row-content:before,
.fl-row-content:after,
.fl-col-group:before,
.fl-col-group:after,
.fl-col:before,
.fl-col:after,
.fl-module:before,
.fl-module:after,
.fl-module-content:before,
.fl-module-content:after {
    display: table;
    content: " ";
}

.fl-row:after,
.fl-row-content:after,
.fl-col-group:after,
.fl-col:after,
.fl-module:after,
.fl-module-content:after {
    clear: both;
}

.fl-row,
.fl-row-content,
.fl-col-group,
.fl-col,
.fl-module,
.fl-module-content {
    zoom: 1;
}

.fl-clear {
    clear: both;
}

.fl-clearfix:before,
.fl-clearfix:after {
    display: table;
    content: " ";
}

.fl-clearfix:after {
    clear: both;
}

.fl-clearfix {
    zoom: 1;
}

.fl-visible-medium,
.fl-visible-medium-mobile,
.fl-visible-mobile,
.fl-col-group .fl-visible-medium.fl-col,
.fl-col-group .fl-visible-medium-mobile.fl-col,
.fl-col-group .fl-visible-mobile.fl-col {
    display: none;
}

.fl-row,
.fl-row-content {
    margin-left: auto;
    margin-right: auto;
}

.fl-row-content-wrap {
    position: relative;
}

.fl-builder-mobile .fl-row-bg-photo .fl-row-content-wrap {
    background-attachment: scroll;
}

.fl-row-bg-video,
.fl-row-bg-video .fl-row-content {
    position: relative;
}

.fl-row-bg-video .fl-bg-video {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
}

.fl-row-bg-video .fl-bg-video video {
    bottom: 0;
    left: 0px;
    max-width: none;
    position: absolute;
    right: 0;
    top: 0px;
}

.fl-row-bg-video .fl-bg-video iframe {
    pointer-events: none;
    width: 100vw;
    height: 56.25vw;
    max-width: none;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.fl-bg-video-fallback {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0px;
    left: 0px;
    position: absolute;
    right: 0px;
    top: 0px;
}

.fl-row-bg-slideshow,
.fl-row-bg-slideshow .fl-row-content {
    position: relative;
}

.fl-row .fl-bg-slideshow {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}

.fl-builder-edit .fl-row .fl-bg-slideshow * {
    bottom: 0;
    height: auto !important;
    left: 0;
    position: absolute !important;
    right: 0;
    top: 0;
}

.fl-row-bg-overlay .fl-row-content-wrap:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.fl-row-bg-overlay .fl-row-content {
    position: relative;
    z-index: 1;
}

.fl-row-full-height .fl-row-content-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
}

.fl-builder-edit .fl-row-full-height .fl-row-content-wrap {
    min-height: calc( 100vh - 48px);
}

.fl-row-full-height .fl-row-content {
    -webkit-box-flex: 1 1 auto;
    -moz-box-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.fl-row-full-height .fl-row-full-width.fl-row-content {
    max-width: 100%;
    width: 100%;
}

.fl-builder-ie-11 .fl-row.fl-row-full-height:not(.fl-visible-medium):not(.fl-visible-medium-mobile):not(.fl-visible-mobile) {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.fl-builder-ie-11 .fl-row-full-height .fl-row-content-wrap {
    height: auto;
}

.fl-builder-ie-11 .fl-row-full-height .fl-row-content {
    flex: 0 0 auto;
    flex-basis: 100%;
    margin: 0;
}

.fl-builder-ie-11 .fl-row-full-height.fl-row-align-top .fl-row-content,
.fl-builder-ie-11 .fl-row-full-height.fl-row-align-bottom .fl-row-content {
    margin: 0 auto;
}

.fl-builder-ie-11 .fl-row-full-height.fl-row-align-center .fl-col-group:not(.fl-col-group-equal-height) {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
}

.fl-row-full-height.fl-row-align-center .fl-row-content-wrap {
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
}

.fl-row-full-height.fl-row-align-bottom .fl-row-content-wrap {
    align-items: flex-end;
    justify-content: flex-end;
    -webkit-align-items: flex-end;
    -webkit-justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-box-pack: end;
    -ms-flex-align: end;
    -ms-flex-pack: end;
}

.fl-builder-ie-11 .fl-row-full-height.fl-row-align-bottom .fl-row-content-wrap {
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    .fl-row-full-height .fl-row-content-wrap {
        min-height: 1024px;
    }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    .fl-row-full-height .fl-row-content-wrap {
        min-height: 768px;
    }
}

@media screen and (device-aspect-ratio: 40/71) {
    .fl-row-full-height .fl-row-content-wrap {
        min-height: 500px;
    }
}

.fl-col-group-equal-height,
.fl-col-group-equal-height .fl-col,
.fl-col-group-equal-height .fl-col-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.fl-col-group-equal-height {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.fl-col-group-equal-height.fl-col-group-has-child-loading {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.fl-col-group-equal-height .fl-col,
.fl-col-group-equal-height .fl-col-content {
    -webkit-box-flex: 1 1 auto;
    -moz-box-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.fl-col-group-equal-height .fl-col-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    flex-shrink: 1;
    min-width: 1px;
    max-width: 100%;
    width: 100%;
}

.fl-col-group-equal-height:before,
.fl-col-group-equal-height .fl-col:before,
.fl-col-group-equal-height .fl-col-content:before,
.fl-col-group-equal-height:after,
.fl-col-group-equal-height .fl-col:after,
.fl-col-group-equal-height .fl-col-content:after {
    content: none;
}

.fl-col-group-equal-height.fl-col-group-align-center .fl-col-content {
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
}

.fl-col-group-equal-height.fl-col-group-align-bottom .fl-col-content {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
}

.fl-col-group-equal-height.fl-col-group-align-center .fl-module,
.fl-col-group-equal-height.fl-col-group-align-center .fl-col-group {
    width: 100%;
}

.fl-builder-ie-11 .fl-col-group-equal-height,
.fl-builder-ie-11 .fl-col-group-equal-height .fl-col,
.fl-builder-ie-11 .fl-col-group-equal-height .fl-col-content,
.fl-builder-ie-11 .fl-col-group-equal-height .fl-module,
.fl-col-group-equal-height.fl-col-group-align-center .fl-col-group {
    min-height: 1px;
}

.fl-col {
    float: left;
    min-height: 1px;
}

.fl-col-bg-overlay .fl-col-content {
    position: relative;
}

.fl-col-bg-overlay .fl-col-content:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.fl-col-bg-overlay .fl-module {
    position: relative;
    z-index: 2;
}

.fl-module img {
    max-width: 100%;
}

.fl-builder-module-template {
    margin: 0 auto;
    max-width: 1100px;
    padding: 20px;
}

.fl-builder-content a.fl-button,
.fl-builder-content a.fl-button:visited {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    line-height: 18px;
    padding: 12px 24px;
    text-decoration: none;
    text-shadow: none;
}

.fl-builder-content .fl-button:hover {
    text-decoration: none;
}

.fl-builder-content .fl-button:active {
    position: relative;
    top: 1px;
}

.fl-builder-content .fl-button-width-full .fl-button {
    display: block;
    text-align: center;
}

.fl-builder-content .fl-button-width-custom .fl-button {
    display: inline-block;
    text-align: center;
    max-width: 100%;
}

.fl-builder-content .fl-button-left {
    text-align: left;
}

.fl-builder-content .fl-button-center {
    text-align: center;
}

.fl-builder-content .fl-button-right {
    text-align: right;
}

.fl-builder-content .fl-button i {
    font-size: 1.3em;
    height: auto;
    margin-right: 8px;
    vertical-align: middle;
    width: auto;
}

.fl-builder-content .fl-button i.fl-button-icon-after {
    margin-left: 8px;
    margin-right: 0;
}

.fl-builder-content .fl-button-has-icon .fl-button-text {
    vertical-align: middle;
}

.fl-icon-wrap {
    display: inline-block;
}

.fl-icon {
    display: table-cell;
    vertical-align: middle;
}

.fl-icon a {
    text-decoration: none;
}

.fl-icon i {
    float: left;
}

.fl-icon i:before {
    border: none !important;
}

.fl-icon-text {
    display: table-cell;
    text-align: left;
    padding-left: 15px;
    vertical-align: middle;
}

.fl-icon-text *:last-child {
    margin: 0 !important;
    padding: 0 !important;
}

.fl-icon-text a {
    text-decoration: none;
}

.fl-photo {
    line-height: 0;
    position: relative;
}

.fl-photo-align-left {
    text-align: left;
}

.fl-photo-align-center {
    text-align: center;
}

.fl-photo-align-right {
    text-align: right;
}

.fl-photo-content {
    display: inline-block;
    line-height: 0;
    position: relative;
    max-width: 100%;
}

.fl-photo-img-svg {
    width: 100%;
}

.fl-photo-content img {
    display: inline;
    height: auto;
    max-width: 100%;
}

.fl-photo-crop-circle img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.fl-photo-caption {
    font-size: 13px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fl-photo-caption-below {
    padding-bottom: 20px;
    padding-top: 10px;
}

.fl-photo-caption-hover {
    background: rgba(0, 0, 0, 0.7);
    bottom: 0;
    color: #fff;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    padding: 10px 15px;
    position: absolute;
    right: 0;
    -webkit-transition: opacity 0.3s ease-in;
    -moz-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
}

.fl-photo-content:hover .fl-photo-caption-hover {
    opacity: 100;
    filter: alpha(opacity=100);
}

.fl-builder-pagination,
.fl-builder-pagination-load-more {
    padding: 40px 0;
}

.fl-builder-pagination ul.page-numbers {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

.fl-builder-pagination li {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
}

.fl-builder-pagination li a.page-numbers,
.fl-builder-pagination li span.page-numbers {
    border: 1px solid #e6e6e6;
    display: inline-block;
    padding: 5px 10px;
    margin: 0 0 5px;
}

.fl-builder-pagination li a.page-numbers:hover,
.fl-builder-pagination li span.current {
    background: #f5f5f5;
    text-decoration: none;
}

.fl-slideshow,
.fl-slideshow * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.fl-slideshow .fl-slideshow-image img {
    max-width: none !important;
}

.fl-slideshow-social {
    line-height: 0 !important;
}

.fl-slideshow-social * {
    margin: 0 !important;
}

.fl-builder-content .bx-wrapper .bx-viewport {
    background: transparent;
    border: none;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    left: 0;
}

.mfp-wrap button.mfp-arrow,
.mfp-wrap button.mfp-arrow:active,
.mfp-wrap button.mfp-arrow:hover,
.mfp-wrap button.mfp-arrow:focus {
    background: transparent !important;
    border: none !important;
    outline: none;
    position: absolute;
    top: 50%;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.mfp-wrap .mfp-close,
.mfp-wrap .mfp-close:active,
.mfp-wrap .mfp-close:hover,
.mfp-wrap .mfp-close:focus {
    background: transparent !important;
    border: none !important;
    outline: none;
    position: absolute;
    top: 0;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.admin-bar .mfp-wrap .mfp-close,
.admin-bar .mfp-wrap .mfp-close:active,
.admin-bar .mfp-wrap .mfp-close:hover,
.admin-bar .mfp-wrap .mfp-close:focus {
    top: 32px!important;
}

img.mfp-img {
    padding: 0;
}

.mfp-counter {
    display: none;
}

.mfp-wrap .mfp-preloader.fa {
    font-size: 30px;
}

.fl-form-field {
    margin-bottom: 15px;
}

.fl-form-field input.fl-form-error {
    border-color: #DD6420;
}

.fl-form-error-message {
    clear: both;
    color: #DD6420;
    display: none;
    padding-top: 8px;
    font-size: 12px;
    font-weight: lighter;
}

.fl-form-button-disabled {
    opacity: 0.5;
}

.fl-animation {
    opacity: 0;
}

.fl-builder-mobile .fl-animation,
.fl-builder-edit .fl-animation,
.fl-builder-preview .fl-animation,
.fl-animated {
    opacity: 1;
}

.fl-animated.fl-fade-in {
    animation: fl-fade-in 1s ease-out;
    -webkit-animation: fl-fade-in 1s ease-out;
    -moz-animation: fl-fade-in 1s ease-out;
}

@keyframes fl-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fl-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fl-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fl-animated.fl-slide-left {
    animation: fl-slide-left 1s ease-out;
    -webkit-animation: fl-slide-left 1s ease-out;
    -moz-animation: fl-slide-left 1s ease-out;
}

@keyframes fl-slide-left {
    0% {
        opacity: 0;
        transform: translateX(10%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@-webkit-keyframes fl-slide-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(10%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
}

@-moz-keyframes fl-slide-left {
    0% {
        opacity: 0;
        -moz-transform: translateX(10%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0%);
    }
}

.fl-animated.fl-slide-right {
    animation: fl-slide-right 1s ease-out;
    -webkit-animation: fl-slide-right 1s ease-out;
    -moz-animation: fl-slide-right 1s ease-out;
}

@keyframes fl-slide-right {
    0% {
        opacity: 0;
        transform: translateX(-10%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@-webkit-keyframes fl-slide-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-10%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
}

@-moz-keyframes fl-slide-right {
    0% {
        opacity: 0;
        -moz-transform: translateX(-10%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0%);
    }
}

.fl-animated.fl-slide-up {
    animation: fl-slide-up 1s ease-out;
    -webkit-animation: fl-slide-up 1s ease-out;
    -moz-animation: fl-slide-up 1s ease-out;
}

@keyframes fl-slide-up {
    0% {
        opacity: 0;
        transform: translateY(10%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@-webkit-keyframes fl-slide-up {
    0% {
        opacity: 0;
        -webkit-transform: translateY(10%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0%);
    }
}

@-moz-keyframes fl-slide-up {
    0% {
        opacity: 0;
        -moz-transform: translateY(10%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateY(0%);
    }
}

.fl-animated.fl-slide-down {
    animation: fl-slide-down 1s ease-out;
    -webkit-animation: fl-slide-down 1s ease-out;
    -moz-animation: fl-slide-down 1s ease-out;
}

@keyframes fl-slide-down {
    0% {
        opacity: 0;
        transform: translateY(-10%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@-webkit-keyframes fl-slide-down {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-10%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0%);
    }
}

@-moz-keyframes fl-slide-down {
    0% {
        opacity: 0;
        -moz-transform: translateY(-10%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateY(0%);
    }
}

.fl-button.fl-button-icon-animation i {
    width: 0 !important;
    opacity: 0;
    -ms-filter: "alpha(opacity=0)";
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
}

.fl-button.fl-button-icon-animation:hover i {
    opacity: 1! important;
    -ms-filter: "alpha(opacity=100)";
}

.fl-button.fl-button-icon-animation i.fl-button-icon-after {
    margin-left: 0px !important;
}

.fl-button.fl-button-icon-animation:hover i.fl-button-icon-after {
    margin-left: 10px !important;
}

.fl-button.fl-button-icon-animation i.fl-button-icon-before {
    margin-right: 0 !important;
}

.fl-button.fl-button-icon-animation:hover i.fl-button-icon-before {
    margin-right: 20px !important;
    margin-left: -10px;
}

.single:not(.woocommerce).single-fl-builder-template .fl-content {
    width: 100%;
}

.fl-row-content-wrap {
    margin: 0px;
}

.fl-row-content-wrap {
    padding: 20px;
}

.fl-row-fixed-width {
    max-width: 1100px;
}

.fl-module-content {
    margin: 20px;
}

@media (max-width: 992px) {
    .fl-visible-desktop,
    .fl-visible-mobile,
    .fl-col-group .fl-visible-desktop.fl-col,
    .fl-col-group .fl-visible-mobile.fl-col {
        display: none;
    }
    .fl-visible-desktop-medium,
    .fl-visible-medium,
    .fl-visible-medium-mobile,
    .fl-col-group .fl-visible-desktop-medium.fl-col,
    .fl-col-group .fl-visible-medium.fl-col,
    .fl-col-group .fl-visible-medium-mobile.fl-col {
        display: block;
    }
}

@media (max-width: 768px) {
    .fl-visible-desktop,
    .fl-visible-desktop-medium,
    .fl-visible-medium,
    .fl-col-group .fl-visible-desktop.fl-col,
    .fl-col-group .fl-visible-desktop-medium.fl-col,
    .fl-col-group .fl-visible-medium.fl-col {
        display: none;
    }
    .fl-visible-medium-mobile,
    .fl-visible-mobile,
    .fl-col-group .fl-visible-medium-mobile.fl-col,
    .fl-col-group .fl-visible-mobile.fl-col {
        display: block;
    }
    .fl-row-content-wrap {
        background-attachment: scroll !important;
    }
    .fl-row-bg-parallax .fl-row-content-wrap {
        background-attachment: scroll !important;
        background-position: center center !important;
    }
    .fl-col-group.fl-col-group-equal-height {
        display: block;
    }
    .fl-col-group.fl-col-group-equal-height.fl-col-group-custom-width {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    .fl-col-group.fl-col-group-responsive-reversed {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        flex-flow: row wrap;
        -ms-box-orient: horizontal;
        -webkit-flex-flow: row wrap;
    }
    .fl-col-group.fl-col-group-responsive-reversed .fl-col {
        -webkit-box-flex: 0 0 100%;
        -moz-box-flex: 0 0 100%;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .fl-col-group-responsive-reversed .fl-col:nth-of-type(1) {
        -webkit-box-ordinal-group: 12;
        -moz-box-ordinal-group: 12;
        -ms-flex-order: 12;
        -webkit-order: 12;
        order: 12;
    }
    .fl-col-group-responsive-reversed .fl-col:nth-of-type(2) {
        -webkit-box-ordinal-group: 11;
        -moz-box-ordinal-group: 11;
        -ms-flex-order: 11;
        -webkit-order: 11;
        order: 11;
    }
    .fl-col-group-responsive-reversed .fl-col:nth-of-type(3) {
        -webkit-box-ordinal-group: 10;
        -moz-box-ordinal-group: 10;
        -ms-flex-order: 10;
        -webkit-order: 10;
        order: 10;
    }
    .fl-col-group-responsive-reversed .fl-col:nth-of-type(4) {
        -webkit-box-ordinal-group: 9;
        -moz-box-ordinal-group: 9;
        -ms-flex-order: 9;
        -webkit-order: 9;
        order: 9;
    }
    .fl-col-group-responsive-reversed .fl-col:nth-of-type(5) {
        -webkit-box-ordinal-group: 8;
        -moz-box-ordinal-group: 8;
        -ms-flex-order: 8;
        -webkit-order: 8;
        order: 8;
    }
    .fl-col-group-responsive-reversed .fl-col:nth-of-type(6) {
        -webkit-box-ordinal-group: 7;
        -moz-box-ordinal-group: 7;
        -ms-flex-order: 7;
        -webkit-order: 7;
        order: 7;
    }
    .fl-col-group-responsive-reversed .fl-col:nth-of-type(7) {
        -webkit-box-ordinal-group: 6;
        -moz-box-ordinal-group: 6;
        -ms-flex-order: 6;
        -webkit-order: 6;
        order: 6;
    }
    .fl-col-group-responsive-reversed .fl-col:nth-of-type(8) {
        -webkit-box-ordinal-group: 5;
        -moz-box-ordinal-group: 5;
        -ms-flex-order: 5;
        -webkit-order: 5;
        order: 5;
    }
    .fl-col-group-responsive-reversed .fl-col:nth-of-type(9) {
        -webkit-box-ordinal-group: 4;
        -moz-box-ordinal-group: 4;
        -ms-flex-order: 4;
        -webkit-order: 4;
        order: 4;
    }
    .fl-col-group-responsive-reversed .fl-col:nth-of-type(10) {
        -webkit-box-ordinal-group: 3;
        -moz-box-ordinal-group: 3;
        -ms-flex-order: 3;
        -webkit-order: 3;
        order: 3;
    }
    .fl-col-group-responsive-reversed .fl-col:nth-of-type(11) {
        -webkit-box-ordinal-group: 2;
        -moz-box-ordinal-group: 2;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
    }
    .fl-col-group-responsive-reversed .fl-col:nth-of-type(12) {
        -webkit-box-ordinal-group: 1;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
    }
    .fl-col {
        clear: both;
        float: none;
        margin-left: auto;
        margin-right: auto;
        width: auto !important;
    }
    .fl-col-small {
        max-width: 400px;
    }
    .fl-block-col-resize {
        display: none;
    }
    .fl-row[data-node] .fl-row-content-wrap {
        border-left: none;
        border-right: none;
        margin: 0;
        padding-left: 0;
        padding-right: 0;
    }
    .fl-row[data-node] .fl-bg-video,
    .fl-row[data-node] .fl-bg-slideshow {
        left: 0;
        right: 0;
    }
    .fl-col[data-node] .fl-col-content {
        border-left: none;
        border-right: none;
        margin: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

.page .fl-post-header,
.single-fl-builder-template .fl-post-header {
    display: none;
}

.fl-node-5b88364b16fa5 {
    color: #ffffff;
}

.fl-builder-content .fl-node-5b88364b16fa5 *:not(input):not(textarea):not(select):not(a):not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(.fl-menu-mobile-toggle) {
    color: inherit;
}

.fl-builder-content .fl-node-5b88364b16fa5 a {
    color: #ffffff;
}

.fl-builder-content .fl-node-5b88364b16fa5 a:hover {
    color: #ffffff;
}

.fl-builder-content .fl-node-5b88364b16fa5 h1,
.fl-builder-content .fl-node-5b88364b16fa5 h2,
.fl-builder-content .fl-node-5b88364b16fa5 h3,
.fl-builder-content .fl-node-5b88364b16fa5 h4,
.fl-builder-content .fl-node-5b88364b16fa5 h5,
.fl-builder-content .fl-node-5b88364b16fa5 h6,
.fl-builder-content .fl-node-5b88364b16fa5 h1 a,
.fl-builder-content .fl-node-5b88364b16fa5 h2 a,
.fl-builder-content .fl-node-5b88364b16fa5 h3 a,
.fl-builder-content .fl-node-5b88364b16fa5 h4 a,
.fl-builder-content .fl-node-5b88364b16fa5 h5 a,
.fl-builder-content .fl-node-5b88364b16fa5 h6 a {
    color: #ffffff;
}

.fl-node-5b88364b16fa5 .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5b88364b16fa5 .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5b88364b16fa5 .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5b88364b16fa5 > .fl-row-content-wrap {
    margin-top: -30px;
}

.fl-node-5b88364b16fa5 > .fl-row-content-wrap {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
}

.fl-node-5bfcc6ca3f8c3 .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5bfcc6ca3f8c3 .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5bfcc6ca3f8c3 .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5bfcc6ca3f8c3 > .fl-row-content-wrap {
    padding-top: 0px;
}

.fl-node-5bfccdf460b13 .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5bfccdf460b13 .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5bfccdf460b13 .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5b88364b16d50 .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5b88364b16d50 .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5b88364b16d50 .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5b88364b16d50 > .fl-row-content-wrap {
    padding-top: 0px;
    padding-bottom: 0px;
}

.fl-node-5bfcaadad6ffe .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5bfcaadad6ffe .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5bfcaadad6ffe .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5bfca75d3e67b .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5bfca75d3e67b .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5bfca75d3e67b .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5bfcab6545c50 .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5bfcab6545c50 .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5bfcab6545c50 .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5bfca78e20305 .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5bfca78e20305 .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5bfca78e20305 .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5bfca78e20305 > .fl-row-content-wrap {
    padding-top: 0px;
    padding-bottom: 010px;
}

.fl-node-5bfcabed7b39a .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5bfcabed7b39a .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5bfcabed7b39a .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5bfcabed7b39a > .fl-row-content-wrap {
    padding-top: 0px;
    padding-bottom: 0px;
}

.fl-node-5bfcac213064e .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5bfcac213064e .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5bfcac213064e .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5bfcad707ea67 .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5bfcad707ea67 .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5bfcad707ea67 .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5bfcad707ea67 > .fl-row-content-wrap {
    padding-top: 0px;
    padding-bottom: 0px;
}

.fl-node-5bfcae681a248 .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5bfcae681a248 .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5bfcae681a248 .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5bfcae681a248 > .fl-row-content-wrap {
    margin-top: 20px;
}

.fl-node-5bfcae681a248 > .fl-row-content-wrap {
    padding-top: 0px;
    padding-bottom: 0px;
}

.fl-node-5bfcb5cfa0de3 .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5bfcb5cfa0de3 .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5bfcb5cfa0de3 .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5bfcb5cfa0de3 > .fl-row-content-wrap {
    padding-top: 0px;
    padding-bottom: 0px;
}

.fl-node-5bfcb39dbe7f0 .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5bfcb39dbe7f0 .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5bfcb39dbe7f0 .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5b88364b16d17 {
    width: 100%;
}

.fl-node-5bfcc6cae03bf {
    width: 100%;
}

.fl-node-5bfccdf4808c7 {
    width: 100%;
}

.fl-node-5b88364b16949 {
    width: 100%;
}

.fl-node-5b88364b16dc3 {
    width: 33.33%;
}

.fl-node-5b88364b16dfd {
    width: 33.33%;
}

.fl-node-5b88364b16bf6 {
    width: 33.33%;
}

.fl-node-5bfcaadae43b6 {
    width: 100%;
}

.fl-node-5bfca75d46b4f {
    width: 100%;
}

.fl-node-5bfca9ce33a46 {
    width: 50%;
}

.fl-node-5bfca9ce33a8b {
    width: 50%;
}

.fl-node-5bfcab655b946 {
    width: 100%;
}

.fl-node-5bfca78e2da50 {
    width: 100%;
}

.fl-node-5bfcabed8f2bd {
    width: 100%;
}

.fl-node-5bfcac2142006 {
    width: 100%;
}

.fl-node-5bfcad7093674 {
    width: 50%;
}

.fl-node-5bfcae967fddf {
    width: 50%;
}

.fl-node-5bfcae6830382 {
    width: 100%;
}

.fl-node-5bfcb5cfb698b {
    width: 100%;
}

.fl-node-5bfcb39f2314d {
    width: 100%;
}

.fl-node-5b91a92f0cba6 > .fl-module-content {
    margin-top: 40px;
    margin-bottom: 0px;
}

@media (max-width: 768px) {
    .fl-node-5b91a92f0cba6 > .fl-module-content {
        margin-top: 20px;
    }
}

.fl-module-heading .fl-heading {
    padding: 0 !important;
    margin: 0 !important;
}

.fl-node-5bfcc21b2a1c8.fl-module-heading .fl-heading {
    text-align: center;
    font-size: 40px;
}

.fl-row .fl-col .fl-node-5bfcc21b2a1c8 h1.fl-heading a,
.fl-row .fl-col .fl-node-5bfcc21b2a1c8 h1.fl-heading .fl-heading-text,
.fl-row .fl-col .fl-node-5bfcc21b2a1c8 h1.fl-heading .fl-heading-text * {
    color: #2175bc;
}

.fl-node-5bfcc21b2a1c8 .fl-heading .fl-heading-text {
    font-family: "Abel", sans-serif;
    font-weight: 400;
}

.fl-node-5bfcc21b2a1c8 > .fl-module-content {
    margin-top: 0px;
    margin-bottom: 0px;
}

.fl-node-5bfcc24522a4f.fl-module-heading .fl-heading {
    text-align: center;
    font-size: 45px;
}

.fl-row .fl-col .fl-node-5bfcc24522a4f h1.fl-heading a,
.fl-row .fl-col .fl-node-5bfcc24522a4f h1.fl-heading .fl-heading-text,
.fl-row .fl-col .fl-node-5bfcc24522a4f h1.fl-heading .fl-heading-text * {
    color: #404042;
}

.fl-node-5bfcc24522a4f .fl-heading .fl-heading-text {
    font-family: "Arimo", sans-serif;
    font-weight: 400;
}

.fl-node-5bfcc24522a4f > .fl-module-content {
    margin-top: 0px;
    margin-bottom: 0px;
}

.fl-node-5bfcc260bfd31.fl-module-heading .fl-heading {
    text-align: center;
    font-size: 55px;
}

.fl-row .fl-col .fl-node-5bfcc260bfd31 h1.fl-heading a,
.fl-row .fl-col .fl-node-5bfcc260bfd31 h1.fl-heading .fl-heading-text,
.fl-row .fl-col .fl-node-5bfcc260bfd31 h1.fl-heading .fl-heading-text * {
    color: #404042;
}

.fl-node-5bfcc260bfd31 .fl-heading .fl-heading-text {
    font-family: "Arimo", sans-serif;
    font-weight: 400;
}

.fl-node-5bfcc260bfd31 > .fl-module-content {
    margin-top: 0px;
    margin-bottom: 20px;
}

.fl-node-5bfcc6ca3f004 .fl-separator {
    border-top: 15px double #cccccc;
    filter: alpha(opacity=100);
    opacity: 1;
    margin: auto;
}

.fl-embed-video iframe {
    max-width: 100%;
}

.fl-wp-video {
    position: relative;
    height: 0;
    overflow: hidden;
    padding: 0 0 56.25%;
}

.fl-wp-video .wp-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.fl-wp-video .wp-video .mejs-container,
.fl-wp-video .wp-video .mejs-inner,
.fl-wp-video .wp-video .mejs-layers,
.fl-wp-video .wp-video .mejs-layer,
.fl-wp-video .wp-video-shortcode,
.fl-wp-video .me-plugin,
.fl-wp-video .me-plugin * {
    width: 100% !important;
    height: 100% !important;
}

@media (max-width: 768px) {}

.fl-node-5b88364b16f6b.fl-module-heading .fl-heading {
    text-align: center;
    font-size: 35px;
}

.fl-node-5b88364b16f6b > .fl-module-content {
    margin-top: 0px;
    margin-bottom: 0px;
}

.fl-callout-left {
    text-align: left;
}

.fl-callout-center {
    text-align: center;
}

.fl-callout-right {
    text-align: right;
}

.fl-callout-right.fl-callout-icon-left,
.fl-callout-right.fl-callout-icon-right {
    display: table;
    float: right;
}

body a.fl-callout-title-link {
    text-decoration: none;
}

body h1.fl-callout-title,
body h2.fl-callout-title,
body h3.fl-callout-title,
body h4.fl-callout-title,
body h5.fl-callout-title,
body h6.fl-callout-title {
    margin: 0;
    padding: 0 0 10px 0;
}

.fl-callout-text p {
    margin: 0 !important;
    padding: 0 0 10px 0 !important;
}

.fl-callout-button {
    padding: 10px 0 0 0;
}

.fl-callout-icon-above-title .fl-icon {
    display: block;
    margin-bottom: 20px;
}

.fl-callout-icon-below-title .fl-icon {
    display: block;
    margin: 10px 0 15px;
}

.fl-callout-icon-above-title .fl-icon i,
.fl-callout-icon-below-title .fl-icon i {
    display: inline-block;
    float: none;
}

.fl-callout-icon-left-title .fl-callout-title,
.fl-callout-icon-right-title .fl-callout-title {
    display: inline-block;
}

.fl-callout-icon-left-title .fl-callout-title span,
.fl-callout-icon-right-title .fl-callout-title span {
    display: table-cell;
    vertical-align: middle;
}

.fl-callout-icon-left-title .fl-icon {
    padding-right: 15px;
}

.fl-callout-icon-right-title .fl-icon {
    padding-left: 15px;
}

.fl-callout-icon-left .fl-icon,
.fl-callout-icon-left-text .fl-icon {
    display: inline;
    float: left;
    padding-right: 15px;
}

.fl-callout-icon-right .fl-icon,
.fl-callout-icon-right-text .fl-icon {
    vertical-align: top;
    padding-left: 15px;
}

.fl-callout-icon-left .fl-callout-content,
.fl-callout-icon-right .fl-callout-content,
.fl-callout-icon-left-text .fl-callout-text-wrap,
.fl-callout-icon-right-text .fl-callout-text-wrap {
    display: table-cell;
}

.fl-callout-right.fl-callout-icon-left .fl-icon {
    display: table-cell;
    vertical-align: top;
}

.fl-callout-photo-above-title .fl-photo {
    margin-bottom: 15px;
}

.fl-callout-photo-below-title .fl-photo {
    margin-bottom: 15px;
    margin-top: 5px;
}

.fl-callout-photo-left {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.fl-callout-photo-left .fl-callout-photo,
.fl-callout-photo-left-text .fl-callout-photo {
    display: table-cell;
    padding-right: 30px;
    width: 50%;
    vertical-align: top;
}

.fl-callout-photo-left .fl-callout-content,
.fl-callout-photo-left-text .fl-callout-text-wrap {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
}

.fl-callout-photo-right {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.fl-callout-photo-right .fl-callout-photo,
.fl-callout-photo-right-text .fl-callout-photo {
    display: table-cell;
    padding-left: 30px;
    width: 50%;
    vertical-align: top;
}

.fl-callout-photo-right .fl-callout-content,
.fl-callout-photo-right-text .fl-callout-text-wrap {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
}

@media (max-width: 768px) {
    .fl-callout-photo-left,
    .fl-callout-photo-right {
        display: block;
    }
    .fl-callout-photo-left .fl-callout-photo,
    .fl-callout-photo-left-text .fl-callout-photo {
        display: block;
        margin-bottom: 15px;
        padding-left: 0;
        padding-right: 0;
        width: auto;
    }
    .fl-callout-photo-right .fl-callout-photo,
    .fl-callout-photo-right-text .fl-callout-photo {
        display: block;
        margin-top: 25px;
        padding-left: 0;
        padding-right: 0;
        width: auto;
    }
    .fl-callout-photo-left .fl-callout-content,
    .fl-callout-photo-left-text .fl-callout-content,
    .fl-callout-photo-right .fl-callout-content,
    .fl-callout-photo-right-text .fl-callout-content {
        display: block;
        width: auto;
    }
}

.fl-node-5b88364b16ee3 .fl-module-content .fl-icon i,
.fl-node-5b88364b16ee3 .fl-module-content .fl-icon i:before {
    color: #434344;
    font-size: 80px;
    height: auto;
    width: auto;
}

.fl-node-5b88364b16ee3 .fl-module-content .fl-icon i:hover,
.fl-node-5b88364b16ee3 .fl-module-content .fl-icon i:hover:before,
.fl-node-5b88364b16ee3 .fl-module-content .fl-icon a:hover i,
.fl-node-5b88364b16ee3 .fl-module-content .fl-icon a:hover i:before {}

.fl-node-5b88364b16ee3 .fl-module-content .fl-icon-text {
    height: 140px;
}

.fl-node-5b88364b16e70 .fl-module-content .fl-icon i,
.fl-node-5b88364b16e70 .fl-module-content .fl-icon i:before {
    color: #2175bc;
    font-size: 80px;
    height: auto;
    width: auto;
}

.fl-node-5b88364b16e70 .fl-module-content .fl-icon i:hover,
.fl-node-5b88364b16e70 .fl-module-content .fl-icon i:hover:before,
.fl-node-5b88364b16e70 .fl-module-content .fl-icon a:hover i,
.fl-node-5b88364b16e70 .fl-module-content .fl-icon a:hover i:before {}

.fl-node-5b88364b16e70 .fl-module-content .fl-icon-text {
    height: 140px;
}

.fl-node-5b88364b16eaa .fl-module-content .fl-icon i,
.fl-node-5b88364b16eaa .fl-module-content .fl-icon i:before {
    color: #bcbec0;
    font-size: 80px;
    height: auto;
    width: auto;
}

.fl-node-5b88364b16eaa .fl-module-content .fl-icon i:hover,
.fl-node-5b88364b16eaa .fl-module-content .fl-icon i:hover:before,
.fl-node-5b88364b16eaa .fl-module-content .fl-icon a:hover i,
.fl-node-5b88364b16eaa .fl-module-content .fl-icon a:hover i:before {}

.fl-node-5b88364b16eaa .fl-module-content .fl-icon-text {
    height: 140px;
}

.fl-node-5bfca75d3e322 .fl-separator {
    border-top: 4px solid #cccccc;
    filter: alpha(opacity=100);
    opacity: 1;
    margin: auto;
}

.fl-node-5bfca75d3e322 > .fl-module-content {
    margin-top: 0px;
    margin-bottom: 0px;
}

@media (max-width: 768px) {
    .fl-module-icon {
        text-align: center !important;
    }
}

.fl-node-5bfcaa23e0c72.fl-module-icon {
    text-align: center
}

.fl-node-5bfcaa23e0c72 .fl-module-content .fl-icon i,
.fl-node-5bfcaa23e0c72 .fl-module-content .fl-icon i:before {
    color: #2175bc;
    font-size: 425px;
    height: auto;
    width: auto;
}

.fl-node-5bfcaa23e0c72 .fl-module-content .fl-icon i:hover,
.fl-node-5bfcaa23e0c72 .fl-module-content .fl-icon i:hover:before,
.fl-node-5bfcaa23e0c72 .fl-module-content .fl-icon a:hover i,
.fl-node-5bfcaa23e0c72 .fl-module-content .fl-icon a:hover i:before {}

.fl-node-5bfcaa23e0c72 .fl-module-content .fl-icon-text {
    height: 743.75px;
}

.fl-node-5bfcaa23e0c72 > .fl-module-content {
    margin-top: 30px;
}

@media (max-width: 768px) {
    .fl-node-5bfcaa23e0c72 > .fl-module-content {
        margin-top: 20px;
    }
}

.fl-node-5bfcab6545800 .fl-separator {
    border-top: 5px solid #cccccc;
    filter: alpha(opacity=100);
    opacity: 1;
    margin: auto;
}

.fl-node-5bfcab6545800 > .fl-module-content {
    margin-top: 0px;
    margin-bottom: 0px;
}

.fl-node-5bfcab8eda252 > .fl-module-content {
    margin-top: 0px;
}

.fl-node-5bfcabed7aee9 .fl-separator {
    border-top: 5px solid #cccccc;
    filter: alpha(opacity=100);
    opacity: 1;
    margin: auto;
}

.fl-node-5bfcabed7aee9 > .fl-module-content {
    margin-top: 0px;
    margin-bottom: 0px;
}

.fl-node-5bfcac212fde3 .fl-module-content .fl-icon i,
.fl-node-5bfcac212fde3 .fl-module-content .fl-icon i:before {
    color: #bcbec0;
    font-size: 40px;
    height: auto;
    width: auto;
}

.fl-node-5bfcac212fde3 .fl-module-content .fl-icon i:hover,
.fl-node-5bfcac212fde3 .fl-module-content .fl-icon i:hover:before,
.fl-node-5bfcac212fde3 .fl-module-content .fl-icon a:hover i,
.fl-node-5bfcac212fde3 .fl-module-content .fl-icon a:hover i:before {}

.fl-node-5bfcac212fde3 .fl-module-content .fl-icon-text {
    height: 70px;
}

.fl-builder-content .fl-node-5bfcad707e480 a.fl-button,
.fl-builder-content .fl-node-5bfcad707e480 a.fl-button:visited {
    font-size: 40px;
    line-height: 42px;
    background: #cccccc;
    border: 1px solid #c0c0c0;
}

.fl-builder-content .fl-node-5bfcad707e480 a.fl-button,
.fl-builder-content .fl-node-5bfcad707e480 a.fl-button:visited,
.fl-builder-content .fl-node-5bfcad707e480 a.fl-button *,
.fl-builder-content .fl-node-5bfcad707e480 a.fl-button:visited * {
    color: #2175bc;
}

.fl-builder-content .fl-node-5bfcad707e480 a.fl-button:hover,
.fl-builder-content .fl-node-5bfcad707e480 a.fl-button:focus {
    background: #cccccc;
    border: 1px solid #c0c0c0;
}

@media ( max-width: 768px) {
    .fl-node-5bfcad707e480 .fl-button-wrap {
        text-align: center;
    }
}

.fl-node-5bfcad707e480 > .fl-module-content {
    margin-top: 0px;
}

.fl-node-5bfcae02e0d35 > .fl-module-content {
    margin-top: 0px;
    margin-bottom: 0px;
}

.fl-builder-content .fl-node-5bfcae96801ff a.fl-button,
.fl-builder-content .fl-node-5bfcae96801ff a.fl-button:visited {
    font-size: 40px;
    line-height: 42px;
    background: #cccccc;
    border: 1px solid #c0c0c0;
}

.fl-builder-content .fl-node-5bfcae96801ff a.fl-button,
.fl-builder-content .fl-node-5bfcae96801ff a.fl-button:visited,
.fl-builder-content .fl-node-5bfcae96801ff a.fl-button *,
.fl-builder-content .fl-node-5bfcae96801ff a.fl-button:visited * {
    color: #2175bc;
}

.fl-builder-content .fl-node-5bfcae96801ff a.fl-button:hover,
.fl-builder-content .fl-node-5bfcae96801ff a.fl-button:focus {
    background: #cccccc;
    border: 1px solid #c0c0c0;
}

@media ( max-width: 768px) {
    .fl-node-5bfcae96801ff .fl-button-wrap {
        text-align: center;
    }
}

.fl-node-5bfcae96801ff > .fl-module-content {
    margin-top: 0px;
}

.fl-node-5bfcae9680240 > .fl-module-content {
    margin-top: 0px;
    margin-bottom: 0px;
}

.fl-node-5bfcae6819c94 .fl-separator {
    border-top: 5px solid #cccccc;
    filter: alpha(opacity=100);
    opacity: 1;
    margin: auto;
}

.fl-node-5bfcae6819c94 > .fl-module-content {
    margin-top: 0px;
    margin-bottom: 0px;
}

.fl-node-5bfcb5cfa06da .fl-separator {
    border-top: 5px solid #cccccc;
    filter: alpha(opacity=100);
    opacity: 1;
    margin: auto;
}

.fl-node-5bfcb5cfa06da > .fl-module-content {
    margin-top: 0px;
    margin-bottom: 0px;
}

.fl-node-5bfcb58edf006.fl-module-heading .fl-heading {
    text-align: left;
}

.fl-input-group {
    margin-bottom: 10px;
    position: relative;
}

.fl-contact-form textarea,
.fl-contact-form input[type=text],
.fl-contact-form input[type=tel],
.fl-contact-form input[type=email] {
    display: inline-block;
    width: 100%;
}

.fl-contact-form textarea {
    min-height: 130px;
}

.fl-contact-form .fl-terms-checkbox input[type="checkbox"] {
    height: 23px;
    width: 23px;
    margin: 0;
    position: absolute;
    left: 2px;
    top: 1px;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-margin-start: 3px;
}

.fl-contact-form .fl-terms-checkbox label {
    height: 26px;
    display: table-cell;
    vertical-align: middle;
    position: relative;
    padding-left: 35px;
    -webkit-padding-start: 40px;
}

.fl-contact-form .fl-terms-checkbox-text {
    margin: 10px 0;
}

.fl-contact-form .fl-contact-required {
    font-style: italic;
    font-size: 12px;
}

.fl-contact-form label {
    display: inline-block;
    margin-right: 10px;
}

.fl-contact-form .fl-contact-error {
    color: #DD4420;
    display: none;
    font-size: 12px;
    font-weight: lighter;
    margin-top: 2px;
}

.fl-contact-form .fl-email .fl-contact-error {
    margin-right: 10%;
}

.fl-contact-form .fl-error textarea,
.fl-contact-form .fl-error input[type=text],
.fl-contact-form .fl-error input[type=tel],
.fl-contact-form .fl-error input[type=email] {
    border-color: #DD4420;
}

.fl-contact-form .fl-error .fl-contact-error {
    display: inline-block;
}

.fl-contact-form .fl-send-error,
.fl-contact-form .fl-success,
.fl-contact-form .fl-success-none,
.fl-contact-form .fl-success-msg {
    position: relative;
    top: 5px;
}

.fl-contact-form .fl-success,
.fl-contact-form .fl-success-none,
.fl-contact-form .fl-success-msg {
    color: #29BB41;
}

.fl-contact-form .fl-send-error {
    color: #DD6420;
}

.fl-disabled {
    opacity: 0.5;
}

.fl-builder-content .fl-node-5bfcb39dbdcfc a.fl-button,
.fl-builder-content .fl-node-5bfcb39dbdcfc a.fl-button:visited {
    font-size: 14px;
    line-height: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
}

@media ( max-width: 768px) {
    .fl-node-5bfcb39dbdcfc .fl-button-wrap {
        text-align: center;
    }
}